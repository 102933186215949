.item-hidden-modal>div{
  color: white;
  border-radius: 14px;
  /* background-image: linear-gradient(to right, #152a45, #1f3f65, #152a45); */
  box-shadow:inset 0 0 20px grey;
  -moz-box-shadow:inset 0 0 20px grey;
  -webkit-box-shadow:inset 0 0 20px grey;
  background-color: #041426;
}

.understand-btn{
    background-color: transparent;
    border: none !important;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 12%);
    background-image: linear-gradient(to right,#29deda 0%,#45aef1 51%,#8562ea 100%) !important;
    border-radius: 18px;
    padding: 7px 12px;
    font-family: "Mulish";
    margin-bottom: 20px !important;

}

.item-hide-understand-btn{
  background-color: transparent;
  border: none !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 12%);
  background-image: linear-gradient(to right,#29deda 0%,#45aef1 51%,#8562ea 100%) !important;
  border-radius: 18px;
  padding: 7px 12px;
  font-family: "Mulish";
  margin-bottom: 20px !important;

}

.header-modal{
  justify-content: center;
}
.header-modal+div{
  text-align: center !important;

}
@media(max-width:440px){
  .header-modal+div{
    font-size: 12px !important;
  }
}
.item-hide-header-modal{
  justify-content: center;
}
.item-hide-header-modal+div{
  text-align: center !important;

}
@media(max-width:440px){
  .item-hide-header-modal+div{
    font-size: 12px !important;
  }
}
