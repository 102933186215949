@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
/* @font-face { font-family: "Somerton Dense"; src: url('./somerton_dense/Somerton Dense df.otf'); }  */
/* @font-face { font-family: "Poppins"; src: url('./Fonts/Poppins/Poppins-Regular.ttf'); }  */
/* @font-face { font-family: "PoppinsBold"; src: url('./Fonts/Poppins/Poppins-Bold.ttf'); }  */
/* @font-face { font-family: "Montserrat"; src: url('./Fonts/Montserrat/Montserrat-SemiBold.ttf'); }  */

@font-face {
    font-family: DbGillSiam;
    src: url("/static/media/DB Gill Siam X Li v3.2.f92ee9f0.ttf");
}
@font-face {
    font-family: MadeBonVoyage;
    src: url(/static/media/made_bon_voyage_regular.e51df74a.otf);
}


::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */
}


:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */
}


::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;

}

:root {
    --theme-color: #17447E;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #001226 !important;
    padding: 0;
    margin: 0;
    color: white;
}

#root {
    overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
    /* font-family: MariWeather !important; */
    color: #fff;
    font-family: MadeBonVoyage;
}

.montserat {
    /* font-family: Montserrat !important; */
}

button {
    /* font-family: Montserrat !important; */
}

button, p, div, a, span ,input,select{
    font-family: DbGillSiam;
    color: #fff;
}

.montseratMedium {
    /* font-family: MontserratMedieum !important; */
}

p {
    /* font-family: MontserratMedieum !important; */
}

.back-color {
    color: #080027 !important;
}

.ai-center {
    align-items: center;
}

.jc-center {
    justify-content: center;
}

.custom-nav .navbar-brand {
    width: 125px;
}

.custom-nav .nav-link {
    color: #fff !important;
    font-size: 20px;
    font-weight: 500;
    transition: all .3s;
    background: linear-gradient(270deg, #fff 0, #fff) 0 100% transparent no-repeat;
    background-size: 0% 2px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.custom-nav .resourceLink {
    position: relative;
    z-index: 2;
}
.custom-nav .resourceLink:hover .dropDown {
    display: block;
}
.custom-nav .resourceLink:focus .dropDown {
    display: block;
}
.custom-nav .resourceLink .dropDown{
    display: none;
    position: absolute;
    padding: 15px 10px;
    background-color: white;
    border-radius: 10px;
    transform: translate(-50%, 0);
    left: 50%;
    top: 39px;
}
.custom-nav .resourceLink ul{
    list-style: none;
    padding: 0;
}
.custom-nav .resourceLink ul li {
    padding: 5px 0;
    border-bottom: 1px solid rgb(227, 227, 227);
}
.custom-nav .resourceLink ul li:last-child {
    border-bottom: none;
}
.custom-nav .resourceLink ul li a{
    color: #000;
    text-decoration: none;
}
.custom-nav .resourceLink ul li a:hover{
    opacity: 0.7;
    text-decoration: none;
}
.custom-nav .socialIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 10px; */
}
.custom-nav .socialIcons a{
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-nav .socialIcons .discord{
    max-width: 100%;
    width: 20px;
    -webkit-filter: brightness(1) invert(1);
            filter: brightness(1) invert(1);
}

.custom-nav .nav-link:hover {
    transition: all .3s;
    background: linear-gradient(270deg, #fff 0, #fff) 0 100% transparent no-repeat;
    background-size: 100% 2px;
}
/* .custom-nav .walletBtn span {
    color: #000;
}
.custom-nav .walletBtn span:hover {
    color: #fff;
} */
.custom-nav .walletBtn {
    background: #fff;
    font-size: 19px;
    padding: 13px 40px;
    border-radius: 8px;
}
.custom-nav .walletIco {
    margin-left: 20px;
    position: relative;
}
.custom-nav .walletIco img{
    width: 30px;
    cursor: pointer;
}
.custom-nav .walletIco .amount{
    display: none;
    background: #fff;
    color: #000;
    padding: 5px 15px;
    position: absolute;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-radius: 10px;
    min-width: 100px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    top: 45px;
    transform: translate(-50%, 0px);
    left: 50%;
    z-index: 1;
}
.custom-nav .walletIco:hover .amount {
    display: block;
}

.custom-nav .walletBtn:hover {
    background: #4A80C7;
    color: #fff;
}
.custom-nav .col2 {
    display: flex;
    align-items: center;
}
.custom-nav .col2 .searchNav{
    width: 290px;
    background: white;
    height: 38px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 7px 0px;
    margin-right: 20px;
}
.custom-nav .col2 .searchNav img{
    margin-right: 8px;
}
.custom-nav .col2 .searchNav input{
    width: 100%;
    height: 100%;
    color: black;
    border: none;
    outline: none;
    font-size: 19px;
}
.custom-nav .col2 .searchNav input::-webkit-input-placeholder{
    color: black;
}
.custom-nav .col2 .searchNav input:-ms-input-placeholder{
    color: black;
}
.custom-nav .col2 .searchNav input::placeholder{
    color: black;
}
footer {
    /* background: #606060; */
    background: linear-gradient(45deg, #D279F5, #422E9B);
    padding: 30px 0;
    padding-top: 0;
}
footer .col1{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: auto;
}
footer .copyRight {
    padding: 10px 0;
    font-size: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #C5C6C8;
    margin-top: 30px;
}
footer .copyRight a{
    color: #fff;
    text-decoration: none;
}
footer .desc {
    color: #fff;
    /* text-align: justify; */
    font-size: 22px;
    max-width: 248px;
}
footer .logoMain {
    width: 160px;
    margin-bottom: 20px;
}
footer .logoMain img{
    max-width: 100%;
}
footer h6 {
    color: #fff;
    font-size: 17px;
    margin: 25px 0;
    margin-top: 35px;
}
footer ul{
    padding-left: 0;
    list-style: none;
}
footer ul li {
    margin: 5px 0;
}
footer ul li a{
    font-size: 19px;
    text-decoration: none;
    color: #fff;
}
footer ul li a:hover{
    text-decoration: none;
    color: #fff;
    opacity: 0.8;
}
footer .miniFooter {
    /* background: #C5C6C8; */
    background: #001226 ;
    padding: 35px 0;
}
footer .miniFooter .colOne{
    background: #001226 ;
}
footer .miniFooter .colOne p{
    font-size: 19px;
}
footer .miniFooter .colTwo p{
    font-size: 19px;
}
.ai-fe {
    align-items: flex-end;
}
footer .colTwo .socialMain img{
    margin-right: 5px;
    max-width: 50px;
    transition:all 0.3s;
}
footer .colTwo .socialMain img:hover {
    transform: scale(1.1);
    transition:all 0.3s;
}
footer .miniFooter .mail {
    display: flex;
    align-items: center;
}
footer .miniFooter .mail input{
    width: 100%;
    padding: 6px 20px;
    border: none;
    border-radius: 8px;
    color:#96989B;
    font-size: 19px;
}
footer .miniFooter .mail input::-webkit-input-placeholder {
   
    color:#96989B;
}
footer .miniFooter .mail input:-ms-input-placeholder {
   
    color:#96989B;
}
footer .miniFooter .mail input::placeholder {
   
    color:#96989B;
}
footer .miniFooter .mail button{
    padding: 5px 20px;
    margin-left: 10px;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 19px;
}

.color-white {
    color: #fff;
}

.text-left {
    text-align: left;
}

.logoutIcon img {
    width: 25px !important;
    margin-left: 20px;
}

.logoutIcon span {
    font-size: 14px;
    font-weight: 600;
    margin-left: 8px;
    color: white;
}

.logoutIcon:hover {
    cursor: pointer;
}



.w-mc {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.pr {
    position: relative;
}

.d-flex {
    display: flex;
}

.jc-sb {
    justify-content: space-between;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}




.imgLoader {
    width: 45px;
    height: 45px;
    display: inline-block;
    padding: 0px;
    border-radius: 100%;
    border: 5px solid;
    border-top-color: rgb(255, 255, 255);
    border-bottom-color: rgba(255, 255, 255, 0.3);
    border-left-color: rgb(255, 255, 255);
    border-right-color: rgba(255, 255, 255, 0.3);
    animation: imgLoader 1s ease-in-out infinite;
}
.mintModalBtn {
    display: flex;
}
.collectiblesCard .imgDiv{
    min-height: 250px;
    max-height: 250px;
    width: 100%;
    overflow: hidden;
}
@keyframes imgLoader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.globalLoader {
    position: fixed;
    top: 0;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    width: 100vw;
    left: 0;
    height: 100vh;
    background: rgba(0, 0, 0, .2);
    z-index: 1111;
}

.globalLoader .loader {
    width: 100%;
    max-width: 300px;
    padding: 40px 0;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: #fff;
    border-radius: 20px;
    position: fixed;
}

.globalLoader .msg {
    color: black;
    font-size: 22px;
    font-weight: 600;
}

.navbar {
    background: linear-gradient(45deg, #4A80C7, #CB71E8);
}
.h-100 {
    height: 100%;
}


@media(min-width:1200px) and (max-width:1400px) {
    footer .desc {
        max-width: 311px;
    }
  
}

@media(min-width:992px) and (max-width:1200px) {

}



@media(min-width:576px) and (max-width:768px) {
 
}

@media(min-width:992px) {
    .custom-nav .nav-item {
        margin: 0 10px ;
    }
}

@media(max-width:992px) {
    footer .colTwo .socialMain img {
        max-width: 40px;
    }
    .custom-nav .walletIco {
        margin-top: 10px;
    }
   

    .logoutIcon {
        margin-top: 20px;
    }

}

@media(max-width:768px) {
    footer .copyRight {
        flex-direction: column;
    }
    footer .copyRight .copy{
        margin-bottom: 10px;
    }
}

@media(max-width:576px) {
    .profilePage .profileImgDiv {
        width: 150px !important;
        height: 150px !important;
        margin-top: -90px !important;
    }
    .custom-nav .col2 {
        flex-direction: column;
    }
    .custom-nav .col2 .searchNav {
        width: 100%;
        margin: 0;
    }
    .custom-nav .walletBtn {
        width: 100%;
        margin-top: 10px;
    }
    footer h6 {
        font-size: 16px;
    }
    footer ul li a {
        font-size: 14px;
    }
    footer ul li {
        margin: 10px 0;
    }
    footer .copyRight {
        font-size: 17px;
    }
}

@media(min-width:1200px) and (max-width:1400px) {}


@media(min-width:992px) and (max-width:1200px) {
    .custom-nav .walletBtn {
        padding: 14px 15px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    
}
.time-remaining{
    font-size: 21px;
    line-height: 29px;
    color: #12243a;
    font-weight: bold;
    font-family: "Gotham-Font-Navbar";
    text-align: center;
  }
  .list-status-btn{
    cursor: pointer;
    margin-top: 10px;
    padding: 10px 15px;
    border: 1px solid grey;
    border-radius: 8px;
  }
  
  .listing-modal{
    font-family: 'Mulish', sans-serif;
  }
  

.WalletModal {
  color: #17447E;
}

.WalletModal h1 {
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
}

.WalletModal .walletRow {
  display: flex;
  justify-content: center;
}

.WalletModal .metamask {
  margin-right: 25px;
}

.WalletModal .walletImg {
  width: 210px;
  margin: auto;
  margin-bottom: 30px;
}

.WalletModal .walletConnect .walletImg {
  width: 226px;
  margin: auto;
  margin-bottom: 30px;
}

.WalletModal .btnDiv {
  text-align: center;
  margin-bottom: 30px;
}

.WalletModal .btnDiv button {
  /* background: #05B4EF; */
  background-image: linear-gradient(45deg, #D279F5 0%, #422E9B 50%);
  /* background-image: linear-gradient(90deg,#1fa2ff 0,#12d8fa 51%,#1fa2ff); */
  background-size: 200%;
  width: 145px;
  height: 44px;
  border-radius: 12px;
  border: none;
  font-weight: bold;
  color: #fff;
  margin: auto;
  transition: all 0.3s;
}

.WalletModal .btnDiv button:hover {
  background-position: 99%;
  transition: all 0.3s;
}

.WalletModal .helpDv {
  font-size: 14px;
  text-align: center;
  padding-bottom: 30px;
}

.WalletModal .helpDv img {
  width: 20px;
  margin-right: 5px;
}

.WalletModal .closeIcon {
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
}

.WalletModal .closeIcon i {
  font-size: 25px;
  color: #707070;
}


@media(max-width:576px) {

  .WalletModal .helpDv {
      font-size: 12px;
  }

  .WalletModal .btnDiv button {
      font-size: 14px;
      width: 140px;
      height: 41px;
  }

  .WalletModal .walletImg {
      width: 120px;
  }

  .WalletModal .walletConnect .walletImg {
      width: 129px;
  }

  .WalletModal .metamask {
      margin-right: 5px;
  }

  .WalletModal h1 {
      font-size: 25px;
  }
}


@media(max-width:340px) {
  .WalletModal .btnDiv button {
      font-size: 12px;
      width: 110px;
      height: 32px;
  }

  .WalletModal .walletImg {
      width: 110px;
  }

  .WalletModal .walletConnect .walletImg {
      width: 119px;
  }
}
.basic-single{
  width: 100%;
  text-align: left;
}
.country-option {
  color: black;
  /* padding: 2px 18px; */
}
.country-option > span > span {
  color: black;
  /* padding: 2px 18px; */
}
.country-option > span {
  color: black;
  /* padding: 2px 18px; */
}
.country-option:hover{
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
}

@font-face {
  font-family: MadeBonVoyage;
  src: url(/static/media/made_bon_voyage_regular.e51df74a.otf);
}
@font-face {
  font-family: DbGillSiam;
  src: url("/static/media/DB Gill Siam X Li v3.2.f92ee9f0.ttf");
}
  
  .marketPlaceMain {
    position: relative;
    padding: 70px 0;
    padding-top: 0;
  }
  .marketPlaceMain .effect1 {
    width: 1900px;
    position: absolute;
    top: -850px;
    right: -1020px;
    z-index: -1;
  }

.marketPlaceMain .effect2 {
  width: 1900px;
  position: absolute;
  bottom: -850px;
  left: -1020px;
  z-index: -1;
}
  .marketDetail  {
  }
  .marketPlaceMain .dataOverviewMain {
    color: white;
    display: flex;
    max-width: 500px;
    margin: auto;
    /* font-size: 13px; */
    justify-content: space-between;
    padding: 15px;
    border: 2px solid #203854;
    border-radius: 10px;
    margin-top: 250px;
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    background: rgba(0,0,0,0.4);
  }
  .marketPlaceMain .dataOverviewMain .data {
    text-align: center;
  }
  .marketPlaceMain .dataOverviewMain .data h4{
    margin: 0;
    font-size: 13px;
  }
  .marketPlaceMain .dataOverviewMain .data p{
    font-size: 13px;
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
  }
  .marketPlaceMain .marketMain {
    /* border: 2px solid #203854;
    padding: 8px; */
    /* border-radius: 10px; */
    margin-top: 50px;
  }
  .marketPlaceMain .filSidebar .inputDiv{
    padding-bottom: 10px;
    display: flex;
  }
  .marketPlaceMain .filSidebar .inputDiv input::-webkit-input-placeholder{
    color: #fff;
    font-weight: 500;
  }
  .marketPlaceMain .filSidebar .inputDiv input:-ms-input-placeholder{
    color: #fff;
    font-weight: 500;
  }
  .marketPlaceMain .filSidebar .inputDiv input::placeholder{
    color: #fff;
    font-weight: 500;
  }
  .marketPlaceMain .filSidebar .inputDiv input{
    background: transparent;
    border: 1px solid #ACAEB1;
    border-radius: 6px;
    color: #fff;
    font-size: 18px;
  }
  .marketPlaceMain .filSidebar .price{
    font-size: 15px;
  }
  .marketPlaceMain .filSidebar .applyBtn {
    padding-bottom: 10px;
  }
  .marketPlaceMain .filSidebar .applyBtn button{
    background: rgba(255,255,255,0.1);
    color: white;
    padding: 5px;
    width: 100%;
    font-weight: 500;
    border-radius: 8px;
    border: none;
    font-size: 20px;
  }
  .marketPlaceMain .filSidebar{
    /* height: 100%; */
    background: transparent;
    /* padding: 10px; */
    /* box-shadow: inset 0 0 30px #202d3d; */
    border-radius: 15px;
  }
  .marketPlaceMain .filterMain {
    padding: 20px;
    padding-left: 0;
  }
  .marketPlaceMain .marketMain .itemBtnDiv {
    text-align: center;
    margin-bottom: 10px;
  }
  .marketPlaceMain .marketMain .itemBtnDiv button{
    border: 2px solid #203854;
    padding: 8px 20px;
    border-radius: 10px;
    /* color: white; */
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .marketPlaceMain .marketMain .dropDownCard .card-body{
    padding: 0;
  }
  .marketPlaceMain .marketMain .dropDownCard {
    /* border: 2px solid #384f6a; */
    border-radius: 0;
  }
  .marketPlaceMain .marketMain .dropDownCard.heading {
    border-bottom: 1px solid #ACAEB1;
  }
  .marketPlaceMain .marketMain .dropDownCard>div{
    /* border-top: 1px solid #203854; */
    padding-top: 5px;
  }
  .marketPlaceMain .marketMain .title {
    color: #fff;
    font-weight: normal;
    text-transform: capitalize;
    font-size: 20px;
    width: 100%;
    text-align: left;
    /* border: 2px solid #203854; */
    padding: 10px 0px;
    border-radius: 10px;
  }
  .font-bold {
    font-weight: 500 !important;
  }
  .marketPlaceMain .marketMain .dropDownCard .contentMain {
    max-height: 150px;
    overflow-y: auto;
    /* margin-bottom: 10px; */
  }
  .marketPlaceMain .marketMain .dropDownCard .contentMain .selectContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: transparent;
    color: white;
    border: none;
  }
  .marketPlaceMain .marketMain .dropDownCard .contentMain .selectContent span{
    font-size: 12px;
    font-weight: bold;
  }
  .marketPlaceMain .marketMain .dropDownCard .card-body input{
    /* background: transparent;
    border: 1px solid #6c757d;
    border-left: 1px solid transparent;
    color: white;
    padding-left: 0; */
  }
  .marketPlaceMain .marketMain .dropDownCard .card-body input:focus{
    box-shadow: none;
  }
  .marketPlaceMain .marketMain .dropDownCard .card-body .searchBtn{
    border-right: 1px solid transparent;
  }
  .marketPlaceMain ::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }
  .marketPlaceMain  {
    scrollbar-width: thin;
    /* scrollbar-color: #90A4AE #CFD8DC; */
  }
  .marketPlaceMain ::-webkit-scrollbar-track {
    background: #CFD8DC;
    border-radius: 10px ;
  }
  .marketPlaceMain ::-webkit-scrollbar-thumb {
    background-color: #90A4AE ;
    border-radius: 6px;
    /* border: 3px solid #CFD8DC; */
  }
  .marketPlaceMain button:focus{
    box-shadow: none;
  }
  .marketPlaceMain .marketMain .sortByMain .btn{
    width: 200px;
    border: 1px solid #231F20;
    font-size: 19px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255,255,255,0.1);
    color: white;
  }
  .marketPlaceMain .marketMain .sortByMain .dropdown-toggle::after {
    display: none;
  }
  .marketPlaceMain .marketMain .sortByMain .number p{
    font-size: 25px;
  }
  .marketPlaceMain .marketMain .sortByMain .number h1{
    margin-bottom: 0;
    font-size: 35px;
    font-weight: 700;
  }
  .marketPlaceMain .marketMain .sortByMain .dropdown-menu{
    background: rgb(31 36 62);
    box-shadow: inset 0 0 30px #202d3d;
    width: 100%;
  }
  .marketPlaceMain .marketMain .sortByMain .dropdown-item{
    margin-left: 0;
    color: #fff;
  }
  .marketPlaceMain .marketMain .sortByMain .dropdown-item:hover{
    background: #233f61;
    margin-left: 0;
    color: #fff;
  }
  .marketPlaceMain .marketMain .sortByMain{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
  }
  .marketPlaceMain .marketMain .sortByMain img{
    width: 18px;
  }
  .marketPlaceMain .marketMain .priceMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
  }
  .marketPlaceMain .marketMain .priceMain .col1{
    display: flex;
    flex-wrap: wrap;
    color: white;
    text-transform: uppercase;
  }
  .marketPlaceMain .marketMain .priceMain .col1 span {
    padding: 7px 15px;
    border-radius: 10px;
    border: 2px solid #203854;
    font-size: 13px;
    margin-right: 10px;
    cursor: pointer;
  }
  .marketPlaceMain .marketMain .priceMain .col1 p{
    font-size: 12px;
    margin-bottom: 0;
  }
  .marketPlaceMain .marketMain .priceMain .col1 .item{
    margin-right: 20px;
  }
  .marketPlaceMain .marketMain .priceMain .col2 .dropdown-toggle:hover{
    color: white;
  }
  .marketPlaceMain .marketMain .priceMain .col2 .dropdown-menu{
    background-color: transparent;
    left:unset !important;
    right: 0 !important;
  }
  .marketPlaceMain .themeBtn {
    border: 2px solid #203854;
    padding: 8px 20px;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard {
    border: 2px solid #203854;
    /* padding: 10px; */
    border-radius: 10px;
    color: white;
    font-size: 10px;
    height: 280px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
  .marketPlaceMain .marketMain .nftCardMain .nftCard:hover{
    border: 2px solid #ffffff;
    color: #203854 !important;
    box-shadow: 2px 2px 2px #ffffff;
  }
  .bg-Dark {
    background-color: #1a0e28 !important;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .imgDiv{
    height: 74%;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-bottom: 1px solid #203854;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .imgDiv img{
    min-width: 100%;
    max-width: 100%;
    height: auto;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain{
    padding: 10px;
    height: 26%;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .id{
    margin: 0;
    font-weight: bold;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .contentDiv{
    display: flex;
    justify-content: space-between;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .contentDiv p{
    margin: 0;
    text-transform: uppercase;
    margin: 4px 0;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .contentDiv .amount{
    font-weight: bold;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .contentDiv .amount img{
    height: 14px;
  }
  
  .marketDetail {
    margin: 10px 0;
  }
  .marketDetail .mainImgDiv {
    border: 2px solid #203854;
    /* padding: 10px; */
    border-radius: 10px;
    overflow: hidden;
  }
  .marketDetail .mainImgDiv img{
    width: 100%;
    height: auto;
    border-radius: 7px;
  }
  .marketDetail .themeBorder {
    border: 2px solid #203854;
    border-radius: 10px;
  }
  .marketDetail .nftNameMain {
    color: white;
    padding: 18px;
  }
  .marketDetail .nftNameMain h6{
    text-transform: uppercase;
    font-size: 15px;
  }
  .marketDetail .nftNameMain .address{
    
  }
  .marketDetail .nftNameMain .address span{
    font-size: 15px;
  }
  .marketDetail .nftNameMain .address img{
    width: 33px;
    height: 33px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .marketDetail .buyMain {
    color: white;
    padding: 18px;
  }
  .marketDetail .buyMain .col1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .marketDetail .buyMain .col1 h6{
    margin-bottom: 0;
  }
  .marketDetail .buyMain .col1 .mLink{
    color: white;
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 14px;
    display: inline-block;
  }
  .marketDetail .buyMain .col1 .mLink img{
    margin-left: 5px;
    width: 25px;
  }
  .marketDetail .buyMain .col1 .priceDiv{
   margin-bottom: 10px;
   margin-top: 10px;
  }
  .marketDetail .buyMain .col1 .priceDiv .eth{
   width: 15px;
  }
  
  .marketDetail .buyMain .col1 .buyBtn{
   border: 2px solid #203854;
    padding: 5px 10px;
    background: #fdd803;
    width: 100%;
    border-radius: 10px;
    color: black;
    font-size: 15px;
    text-transform: uppercase;
  }
  .marketDetail .buyMain .col1 .colInner{
    
  }
  .marketDetail .buyMain .col1 .colInner p{
    padding: 8px;
    border: 1px solid #fdd803;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-radius: 5px;
  }
  .marketDetail .buyMain .col1 .colInner button{
    background: #fdd803;
    color: #000;
    border-radius: 10px;
    border: none;
    padding: 8px 10px;
  }
  .marketDetail .buyMain .col1 .offerBtn{
   border: 2px solid #203854;
    padding: 5px 10px;
    width: 100%;
    border-radius: 10px;
    color: white;
    font-size: 15px;
    text-transform: uppercase;
  }
  .marketDetail .attributeMain {
    padding: 18px;
    color: white;
  }
  .marketDetail .attributeMain .attRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .marketDetail .attributeMain .attRow .attrCard .title {
    font-size: 13px;
  }
  .marketDetail .attributeMain .attRow .attrCard img{
    max-height: 22px  ;
  }
  .marketDetail .attributeMain .attRow .attrCard .r3{
    font-size: 12px;
  }
  .marketDetail .attributeMain .attRow .attrCard{
    padding: 10px;
    width: 49%;
    display: inline-block;
    font-size: 13px;
    /* margin: 0 2px; */
    margin-bottom: 12px;
  
  }
  .marketDetail .attributeMain .attRow .attrCard .r2{
    font-size: 13px;
  }
  .text-bold {
    font-weight: bold;
  }
  .marketDetail .attributeMain .attRow .attrCard p{
   margin-bottom: 0;
  }
  .jc-sb {
    justify-content: space-between;
  }
  .marketDetail .collectionInfo {
    padding: 18px;
    color: white;
  }
  .marketDetail .collectionInfo h5{
    font-size: 18px;
  }
  .marketDetail .collectionInfo .name{
    font-weight: 500;
    color: white;
    margin: 10px 0;
    font-size: 14px;
  }
  .marketDetail .collectionInfo .name img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 4px;
  }
  .marketDetail .collectionInfo .desc{
    margin-bottom: 0;
    font-size: 12px;
  }
  .marketDetail .tokenInfo {
    padding: 18px;
    color: white;
    font-size: 12px;
  }
  .marketDetail .tokenInfo h5{
      font-size: 18px;
  }
  .marketDetail .tokenInfo .imgDiv img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .marketDetail .tokenInfo .address{
    font-weight: bold;
    color: #203854;
    text-decoration: none;
    display: flex; 
    align-items: center;
  }
  .marketDetail .tokenInfo .address:hover{
    color: #203854;
    text-decoration: underline;
  }
  .marketDetail .tokenInfo .imgDiv .val{
    font-weight: 500;
  }
  
  .marketMain .itemTableMain {
    color: white;
  }
  .marketMain .itemTableMain tr:nth-child(even){
    background-color: transparent;
  }
  .marketMain .itemTableMain table {
    margin: 0;
  }
  .marketMain .itemTableMain thead {
    background-color: #7e7853;
  }
  .marketMain .itemTableMain tbody tr:hover {
    background-color: #7e7853;
  }
  .marketMain .itemTableMain thead tr td{
    padding: 20px 8px;
  }
  .marketMain .itemTableMain table tr td{
    color: white;
    vertical-align: middle;
    font-size: 12px !important;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .marketMain .itemTableMain .eventDiv{
    display: flex;
  }
  .marketMain .itemTableMain .eventDiv img{
    width: 20px;
    margin-right: 7px;
  }
  .marketMain .itemTableMain .itemDiv{
    display: flex;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .marketMain .itemTableMain .itemDiv .imgdiv{
    width: 48px;
    height: 48px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 10px;
  }
  .marketMain .itemTableMain .itemDiv .imgdiv img{
    width: 100%;
  }
  .marketMain .itemTableMain .priceDiv img{
    height: 20px;
    margin-right: 5px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .marketMain .itemTableMain .address{
    color: #fdd803;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  
  .marketPlaceMain .HeaderSection {
    padding-bottom: 70px;
    margin-bottom: 40px;
    /* background: url(../assets/images/section-img1.png); */
    background-position: top;
    background-size: cover;
    height: 690px;
  }
  .td-none {
    text-decoration: none;
  }
  
  
  
  @media (min-width:576px) and (max-width:1200px) {
    .marketPlaceMain .HeaderSection {
      height: 550px;
    }
  }
  @media(max-width:576px) {
    .marketPlaceMain .marketMain .sortByMain .btn {
      width: 150px;
    }
    .marketPlaceMain .HeaderSection {
      height: 330px;
    }
    .marketPlaceMain .dataOverviewMain {
      margin-top: 100px;
    }
    .marketPlaceMain .marketMain .nftCardMain .nftCard {
        max-width: 350px;
        margin: auto;
        height:auto;
        margin-bottom: 15px;
    }
    .marketDetail .collectionInfo .desc {
      font-size: 10px;
    }
    .marketDetail .tokenInfo {
      font-size: 10px;
    }
  
    .marketDetail .nftNameMain h6 {
      font-size: 14px;
    }
    .marketDetail .nftNameMain .address span{
      font-size: 14px;
    }
    .marketDetail .buyMain .col1 .colInner h5 {
  
      font-size: 13px;
    }
    .marketDetail .buyMain .col1 .offerBtn {
      font-size: 11px;
    }
    .marketDetail .buyMain .col1 .buyBtn {
      font-size: 11px;
    }
    .marketDetail .buyMain .col1 .mLink {
      font-size: 12px;
    }
    .marketDetail .attributeMain .attRow .attrCard {
      font-size: 12px;
    }
    .marketDetail .attributeMain .attRow .attrCard .title {
      font-size: 12px;
    }
    .marketDetail .attributeMain .attRow .attrCard .r2 {
      font-size: 12px;
    }
    .marketDetail .attributeMain h5 {
      font-size: 16px;
    }
  }
  @media(max-width:768px) {
    .marketDetail .attributeMain .attRow .attrCard {
      width: 100%;
    }
  }
  
  .showmore:hover{
    background-color: #000 !important;
    color: white;
    border: 2px solid #000   !important;
  }

  
.marketPlaceMain .artworkCardMain {
    max-width: 450px;
    margin: auto;
    margin-bottom: 30px;

}



 .marketPlaceMain .artworkCard .favorite {
  position: absolute;
  z-index: 1;
  right: 13px;
  top: 13px;
}
.marketPlaceMain .artworkCard .favorite i{
  font-size: 22px;
}
.artworkCard .favorite {
  position: absolute;
  z-index: 1;
  right: 13px;
  top: 13px;
}
.artworkCard .favorite i{
  font-size: 22px;
}
.productDetail .artworkCard .content {
  padding: 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 .artworkCard .content {
  padding: 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 .artworkCard {
  padding: 0;
  position: relative;
    background: rgba(255,255,255,0.1);
    border-radius: 20px;
    color: #000;
    overflow: hidden;
    /* border: 1px solid #ACAEB1; */
    cursor: pointer;
    /* margin-bottom: 30px; */
    transition: all 0.3;
}
 .artworkCard:hover {
  box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 30%);
  transition: all 0.3;
}
.artworkCard .reserveBtn {
  padding: 7px 20px;
  border-radius: 25px;
  color: #fff;
  font-size: 12px;
  border: none;
}
 .artworkCard .favorite{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 7px;
}
.red-color {
  color: red;
}



 .artworkCard .imgDiv {
    width: 100%;
    height: 280px;
    /* border-radius: 30px; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 400px;
    overflow: hidden;
}

 .artworkCard .imgDiv img {
    /* max-width: 100%; */
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
    width: auto;
    height: auto;
    /* min-width: 100%; */
    /* height: -moz-fit-content; */
    /* height: fit-content; */
}
 .artworkCard .nameMain {
  margin-bottom: 10px;
}
 .artworkCard .nameMain h6 {
  font-size: 23px;
  font-weight: normal;
  margin-bottom: 5px;
  font-family: DbGillSiam;
}
 .artworkCard .nameMain p {
  margin-bottom: 0;
  font-size: 17px;
}

 .artworkCard .content {
    padding-top: 15px;
}

 .artworkCard .content .category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

 .artworkCard .content .category h5, h6 {
    margin: 0;
    font-weight: bold;
}

 .artworkCard .content .category p {
    margin: 0;
    font-size: 14px;
}

 .artworkCard .content .profileDiv {
    display: flex;
    align-items: center;
}

 .artworkCard .content .profileDiv .imgDiv {
    width: 44px;
    height: 44px;
    overflow: hidden;
    border-radius: 50%;
    background: #0f0a41;
    margin-right: 10px;
}
.text-underline {
  text-decoration: underline;
}
.bb-1 {
  border-bottom: 1px solid #000;
}

 .artworkCard .content .profileDiv .imgDiv img {
    max-width: 100%;
    height: auto;
}

 .artworkCard .content .profileDiv .amount {
    
}
 .artworkCard .content .profileDiv .amount .pri {
    font-size: 15px;
    margin-bottom: -8px;
}
 .artworkCard .content .profileDiv .amount .am {
    font-size: 23px;
}
.artworkCard .themeBtn {
  margin-top: 5px;
  padding: 1px 10px;
  font-size: 18px;
}
.noDAtaHead {
  text-align: center;
  color: white;
}


  @media (max-width:1400px) and (min-width:1200px) {
    .marketPlaceMain .artworkCard .imgDiv {
        height: 200px;
    }
  }
  @media (max-width:1200px) and (min-width:992px) {
    .marketPlaceMain .artworkCard .imgDiv {
      height: 320px;
    }
    .marketPlaceMain .marketMain .nftCardMain .nftCard {
      font-size: 9px;
    }
    .mkContainer {
      max-width: 990px;
    }
  }
  @media (max-width:992px) and (min-width:768px) {
    .marketPlaceMain .artworkCard .imgDiv {
      height: 222px;
    }
    .marketPlaceMain .marketMain .title {
      font-size: 10px;
    }
  }
.p-cont
{
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
}
.p-details
{
    /* width: 100%; */
}
.product-1
{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.product-1 p {
    font-size: 19px;
}
.product-2 {
    background: rgba(255, 255,255, 0.1);
    border-radius: 10px;
    /* border: 1px solid #C8C7C7; */
    padding: 25px;
}
.p2-price {
    margin-bottom: 0px;
    font-size: 19px;
}
.p2-nums
{
    font-size: 40px;
    /* padding-top: 30px;
    padding-left: 20px; */
}
.p2-percent
{
    margin-top: -10px;
    /* padding-left: 20px; */
    font-size: 19px;
}
.productDetail .chartMain {
    
    margin-top: 50px;
}
.productDetail .chartMain .chartM{
    padding: 20px;
    background: rgba(255,255,255,0.1);
    border-radius: 12px;
    margin-top:0px;
}
.productDetail p {
    font-size: 19px;
}
.productDetail .userLogo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
}
.productDetail .userLogo img{
    width: 100%;
}
.themeBtn {
    border: none;
    border-radius: 8px;
    /* background: linear-gradient(45deg, #D279F5, #422E9B); */
    background: linear-gradient(45deg, #D279F5 0%, #422E9B 50%);
    background-size: 200%;
    transition: all 0.3s;
}
.themeBtn:hover {
    opacity: 0.8;
    background-position: 99%;
    transition: all 0.3s;
}
.p2-btn {
    border: none;
    border-radius: 8px;
    background-color: black;
    background: linear-gradient(45deg, #D279F5, #422E9B);
    height: 55px;
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    margin-top: 30px;
}
.p-ins
{
    /* height: 30px; */
display: flex;
justify-content: flex-end;
}
.likes
{
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    font-size: 20px;
}
.likes i
{
    color: #C8C7C7;
    
}
.shares
{
    padding: 8px 12px;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    margin-left: 6px;
}
.shares img {
    height: 30px;
}
.option
{
    padding: 8px 12px;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-left: 6px;
}

.product-3
{
    display: flex;
    flex-direction: row;
    margin: 40px 0;
}
.pic
{
    width: 50px;
    height: 100%;
}
.ob
{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.pic{
    border-radius: 50px;
}
.p-img
{
    border: 1px solid black;
}
.imgcont
{
    height: 100%;
}
.product-img
{
    /* max-width: 100%; */
   max-width: 100%;
   /* height: 100%; */

}
.tblcont
{
    /* width: 100%; */

}
.tblheadings
{
    display: flex;
    justify-content: space-evenly;
}
.tblcontent
{
    display: flex;
    justify-content: space-evenly;
}
.tblcontent-cont
{
    /* border: 1px solid #C8C7C7; */
    border-radius: 10px;
    background: rgba(255, 255,255, 0.1);
}
.tblcontent-cont .profileDiv {
    display: flex;
    align-items: center;
    min-width: 200px;
}
.tblcontent-cont .profileDiv .logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 7px;
}
.tblcontent-cont .profileDiv .price .bath {
    font-size: 23px;
}
.tblcontent-cont .profileDiv .price .dollar {
    font-size: 17px;
}

.tblcontent-cont .itemDiv {
    display: flex;
    align-items: center;
    min-width: 200px;

}
.tblcontent-cont .itemDiv .logo {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 12px;
}
.tblcontent-cont .itemDiv .price .bath {
    font-size: 20px;
}
.tblcontent-cont .itemDiv .price .dollar {
    font-size: 14px;
}
.tblcontent-cont table tbody {
    border: none !important;
}
.tblcontent-cont table thead {
    border: 1px;
    border-color: rgba(255, 255,255, 0.2);
}
.tblcontent-cont table tr td {
    vertical-align: middle;
    padding: 10px;
    border-color: rgba(255, 255,255, 0.2);
    color: white;
    font-size: 19px;
    
}
.productDetail .tradeHistory {
    padding-top: 50px;
}
.productDetail .thisColectionSec {
    padding-top: 50px;
}
.productDetail .thisColectionSec .cName{
    font-size: 24px;
    margin-bottom: 30px;
}
.price
{
    /* display: flex; */

}
.bicon
{
    margin-top: 5px;
    height: 80%;
}
.bath999
{
margin-top: 5px;
/* margin-right: 5px; */
}
.expiration
{
    /* margin-left: -30px; */
}
.mkof
{
    font-size: 23px;
    border-radius: 10px;
    background-color: black;
    color: white;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 7px;
}
.up 
{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}
.down
{
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
}
.productDetail .propertiesCard {
    background-color: rgba(255, 255,255, 0.1);
    /* border: 1px solid #C8C7C7; */
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    width: 48%;
    margin-bottom: 15px;
}
.productDetail .propertiesCard .property {
    font-size: 20px;
   
}
.productDetail .propertiesCard .value {
    font-size: 24px;
   
}
.productDetail .propertiesCard .perc {
    font-size: 16px;
   
}
.g1
{

/* margin-left: 5px; */
/* margin-right: 10px; */
}
.g2
{
    width: 100%;
border-radius: 10px;
}
.g3
{height: 140px;
    width: 100%;
    border-radius: 10px;
    margin-right: 10px;
}
.g4
{height: 140px;
    border-radius: 10px;
    width: 100%;
}
.det-head
{
    margin-top: 10px;
}
.productDetail {
    padding: 70px 0;
    position: relative;
    overflow: hidden;
}
.productDetail .effect1 {
    width: 1900px;
    position: absolute;
    top: -850px;
    right: -1020px;
    z-index: -1;
  }
.productDetail .effect2 {
    width: 1800px;
    position: absolute;
    top: 790px;
    right: -1020px;
    z-index: -1;
  }
.productDetail .effect3 {
    width: 1800px;
    position: absolute;
    top: 990px;
    left: -1020px;
    z-index: -1;
  }

.editProfileModal .closeDiv {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
.editProfileModal .purchaseMain .check{
    max-width: 70px;
    margin: auto;
    margin-bottom: 20px;
}
.editProfileModal .purchaseMain{
    padding: 40px;
    text-align: center;
}
.editProfileModal .purchaseMain .purchase{
    font-size: 35px;
}
.editProfileModal .purchaseMain .desc{
    font-size: 19px;
}

.editProfileModal .checkForm .r1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 23px;
    margin-top: 20px;
}
.color-white {
    color: white !important;
}
.editProfileModal .checkForm .r1 p{
    font-size: 23px;
    font-weight: 500;
}
.editProfileModal .checkForm .checkCArd {
    background: #fff;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    color: #000;
    border-radius: 20px;
}   
.editProfileModal .checkForm .profileDiv {
    display: flex;
    align-items: center;
}
.editProfileModal .checkForm .profileDiv .logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 7px;
}
.editProfileModal .checkForm .profileDiv .price .bath {
    font-size: 23px;
    color: #000;
}
.editProfileModal .checkForm .profileDiv .price .dollar {
    font-size: 17px;
    color: #000;
}

.editProfileModal .checkForm .itemDiv {
    display: flex;
    align-items: center;
    min-width: 200px;

}
.editProfileModal .checkForm .itemDiv .logo {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 12px;
}
.editProfileModal .checkForm .itemDiv .price .bath {
    font-size: 23px;
    color: #000;
}
.editProfileModal .checkForm .itemDiv .price .dollar {
    font-size: 17px;
    color: #000;
}
.shareLinkIco {
    width: 40px;
    cursor: pointer;
}
.shareLinkIco svg path,.shareLinkIco svg  {
    fill: #fff;
    stroke: #fff;
}
.shareLinkIco.copy svg path,.shareLinkIco.copy svg  {
    fill: green;
    stroke: green;
}
@media (max-width: 992px) {
    .imgcont {
        margin-bottom: 20px;
        text-align: center;
    }
    .shares img {
        width: 24px;
    }
}
.create-loader{
    border: 5px solid #f3f3f3;
    animation: spin 1s linear infinite;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: inline-block;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.iconSocial {
    width: 23px;
}

.item-hidden-modal>div{
  color: white;
  border-radius: 14px;
  /* background-image: linear-gradient(to right, #152a45, #1f3f65, #152a45); */
  box-shadow:inset 0 0 20px grey;
  -moz-box-shadow:inset 0 0 20px grey;
  -webkit-box-shadow:inset 0 0 20px grey;
  background-color: #041426;
}

.understand-btn{
    background-color: transparent;
    border: none !important;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 12%);
    background-image: linear-gradient(to right,#29deda 0%,#45aef1 51%,#8562ea 100%) !important;
    border-radius: 18px;
    padding: 7px 12px;
    font-family: "Mulish";
    margin-bottom: 20px !important;

}

.item-hide-understand-btn{
  background-color: transparent;
  border: none !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 12%);
  background-image: linear-gradient(to right,#29deda 0%,#45aef1 51%,#8562ea 100%) !important;
  border-radius: 18px;
  padding: 7px 12px;
  font-family: "Mulish";
  margin-bottom: 20px !important;

}

.header-modal{
  justify-content: center;
}
.header-modal+div{
  text-align: center !important;

}
@media(max-width:440px){
  .header-modal+div{
    font-size: 12px !important;
  }
}
.item-hide-header-modal{
  justify-content: center;
}
.item-hide-header-modal+div{
  text-align: center !important;

}
@media(max-width:440px){
  .item-hide-header-modal+div{
    font-size: 12px !important;
  }
}

.welcomePage {
    overflow: hidden;
    position: relative;
}
.welcomePage .effect1{
    position: absolute;
    z-index: -1;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 1300px;
    opacity: 0.7;
    width: 1800px;
}
.welcomePage .section1 {
    height: 650px;
    background: url(/static/media/welcomeBanner.f644a198.jpg);
    background-size: cover;
    background-position: center;
    background-color: #09030A;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.welcomePage .section1 .content{
    padding: 70px 0;
    text-align: center;
}
.welcomePage .section1 .content .tokenImg{
    max-width: 200px;
    margin: auto;
}
.welcomePage .section1 .content .welcome{
    color: #fff;
    font-size: 55px;
}
.welcomePage .section1 .content .first{
    color: #fff;
    font-size: 55px;
}
.welcomePage .section1 .content .text{
    color: #fff;
    font-size: 40px;
}
.welcomePage .newItemSec {
    padding: 40px 0;
}
.welcomePage .newItemSec h1{
    color: white;
    font-size: 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ACAEB1;
    margin-bottom: 40px;
}
.welcomePage .contractRow {
    padding: 30px 0;
    margin: 60px 0;
}
.welcomePage .contractRow .content{
    padding: 30px;
    background: rgba(255,255, 255,0.1);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.welcomePage .contractRow .content p{
   margin-bottom: 0;
   font-size: 20px;
   word-break: break-all;   
}
.welcomePage .contractRow .content .buyBtn{
   border-radius: 8px;
   padding: 10px 40px;
   color: white;
   font-size: 30px;
}
.welcomePage .contractRow .content .buyBtn span{
   font-size: 19px;
}
@media (max-width: 992px) {
    .welcomePage .contractRow .content {
        flex-direction: column;
    }
    .welcomePage .contractRow .content .buyBtn {
        margin-top: 20px;
    }
    footer .miniFooter .colTwo {
        margin-top: 20px;
    }
}
.create-header{
  font-size: 42px;
  font-weight: bolder;
}

#img-upload{
  cursor: pointer;
  border: thin dashed;
  color: grey;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;
  border-radius: 25px;
  border-radius: 12px;
  -webkit-filter: drop-shadow(0 0 2.5px rgba(19,19,19,.16));
  filter: drop-shadow(0 0 2.5px rgba(19,19,19,.16));
  background-image: radial-gradient(circle at center,#630070 0,#181839 100%); 
}
#img-upload .gallery{
  max-width: 70px;
}
.create-colMail #img-upload {
  padding-top: 50px;
  padding-bottom: 50px;
}

.card-img{
  border-radius: 25px;
}
.card{
  border-radius: 25px;
  border: none;
}
#title::-webkit-input-placeholder, .property-value::-webkit-input-placeholder, .property-key::-webkit-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white
}
#title:-ms-input-placeholder, .property-value:-ms-input-placeholder, .property-key:-ms-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white
}
#title::placeholder, .property-value::placeholder, .property-key::placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white
}
#title, .property-key, .property-value{
  background: none;
  border: none;
  caret-color: #fff;
  height: 40px;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 33px;
  color: #fff;
  font-family: "Mulish";
  width: 100%;
  box-shadow: inset 0 0 10px #58006a;
  -moz-box-shadow: inset 0 0 10px #58006a;
  -webkit-box-shadow: inset 0 0 10px #58006a;
  border-radius: 10px;
  background-color: #041426;
}
#custom-select,#select-collection{
  box-shadow:inset 0 0 10px #58006a;
  -moz-box-shadow:inset 0 0 10px #58006a;
  -webkit-box-shadow:inset 0 0 10px #58006a;
  height: 40px;
  border-radius: 10px;
  background-color: #041426;
  color: white;
  font-style: italic;  
  
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(/static/media/download.8126ded7.png),linear-gradient(#051427 100%,#051427 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .9em top 50%, 0 0;
  background-size: .65em auto, 100%;
  padding-left: 10px !important;
  border: none !important;
  cursor: pointer;
}

#custom-select:focus-visible,#select-collection:focus-visible{
  outline: 0px !important;
}

#descript::-webkit-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  color: white;
  font-weight: lighter;
  font-style: italic;
}

#descript:-ms-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  color: white;
  font-weight: lighter;
  font-style: italic;
}

#descript::placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  color: white;
  font-weight: lighter;
  font-style: italic;
}

.create-loader{
  border: 5px solid #f3f3f3;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: inline-block;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


#descript{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  height: 40px;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 33px;
  font-family: "Mulish";
  height: 150px;
  width: inherit;
  box-shadow:inset 0 0 10px #58006a;
  -moz-box-shadow:inset 0 0 10px #58006a;
  -webkit-box-shadow:inset 0 0 10px #58006a;
  border-radius: 10px;
  background-color: #041426;
}

#title:focus, #descript:focus, .property-value:focus, .property-key:focus{
  outline: none;
}
.create-btn{
  -webkit-filter: drop-shadow(1.338px 1.486px 0.5px rgba(19,19,19,.17));
          filter: drop-shadow(1.338px 1.486px 0.5px rgba(19,19,19,.17));
  background-image: linear-gradient(90deg,#17e9d9,#4fa3e1 50%,#875fe9);
  border: none;
  color: #fff;
  display: inline-block;
  padding: 5px 30px;
  border-radius: 25px;
  font-size: 24px;
  /* -webkit-filter: drop-shadow(1.338px 1.486px .5px rgba(19,19,19,.17)); */
}
.profilePage .banner {
    height: 320px;
    overflow: hidden;
}
.profilePage .banner .img{
    min-width: 100%;
    height: 100%;
}
.profilePage .profileImgDiv {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    padding: 7px;
    background-color: white;
    overflow: hidden;
    margin-top: -140px;
    position: relative;
    z-index: 1;
}
.profilePage .profileImgDiv img{
    /* width: 100%; */
    height: 100%;
    width: auto;
    object-fit: contain;
    object-position: center;
}
.profilePage .sidebarContent {
    color: #231F20;
    padding-bottom: 40px;
}
.profilePage .sidebarContent h3{
    font-size: 40px;
    color: #231F20;
    margin: 0;
    font-weight: bold;
}
.font-700 {
    font-weight: 700;
}
.profilePage .sidebarContent .id {
    font-size: 25px;
}
.profilePage .sidebarContent .address{
    font-size: 22px;
}
.profilePage .sidebarContent .name{
    font-size: 19px;
    margin-top: 45px;
    margin-bottom: 10px;
}
.profilePage .sidebarContent .desc{
    font-size: 21px;
    margin-bottom: 30px;
    display: block;
}
.profilePage .sidebarContent .editBtn{
    width: 100%;
    padding: 10px;
    border: 1px solid #fff;
    background: transparent;
    border-radius: 8px;
    font-size: 21px;
}
.profilePage .userNavigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #231F20;
}
.profilePage .userNavigation .links{
    display: flex;align-content: center;
}

.profilePage .userNavigation .links .link{
    font-size: 27px;
    margin-right: 40px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
}
.profilePage .userNavigation .links .link:hover::after, .profilePage .userNavigation .links .link.active::after {
    content: '';
    width: 100%;
    position: absolute;
    height: 3px;
    background-color: black;
    left: 0;
    bottom: -10px;
    transition: all 0.3s;
}
.profilePage .userNavigation .links .link.active::after {
    content: '';
    width: 100%;
    position: absolute;
    height: 3px;
    background-color: black;
    left: 0;
    bottom: -10px;
    transition: all 0.3s;
}
.profilePage .userNavigation .offer{
    font-size: 24px;
    cursor: pointer;
}
.profilePage .artworkCard {
    padding: 0;
}

.profilePage .artworkCard .transfer {
    position: absolute;
    z-index: 1;
    left: 13px;
    top: 13px;
}
.profilePage .artworkCard .transfer i{
    font-size: 22px;
}
.profilePage .artworkCard .favorite {
    position: absolute;
    z-index: 1;
    right: 13px;
    top: 13px;
}
.profilePage .artworkCard .favorite i{
    font-size: 22px;
}
.profilePage .artworkCard .content {
    padding: 15px 10px;
}

/* edti prifile */
.editProfileModal .banner {
    height: 185px;
    overflow: hidden;
    background: rgb(255 255 255 / 30%);
    position: relative;
}
.modal-content {
    background: rgb(89 89 89 / 80%) !important;
    -webkit-backdrop-filter: blur(13px);
            backdrop-filter: blur(13px);
    border-radius: 20px !important;
}
.editProfileModal .banner .editIco{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    cursor: pointer;
    display: none;
}
.editProfileModal .banner:hover .editIco{
   display: block;
}
.editProfileModal .banner .img{
    min-width: 100%;
    height: 100%;
}
.editProfileModal .profileImgDiv .imgDiv {
    background-color: #C2C2C2;
    width: 100%;
    height: 100%;
    border-radius: 50%;

}
.editProfileModal .profileImgDiv {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    padding: 7px;
    background-color: white;
    overflow: hidden;
    margin-top: -104px;
    position: relative;
    z-index: 1;
}
.editProfileModal .profileImgDiv img{
    /* width: 100%; */
    height: 100%;
    width: auto;
    object-fit: contain;
    object-position: center;
}
.editProfileModal .profileImgDiv .editIco{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    cursor: pointer;
    display: none;
}
.editProfileModal .profileImgDiv:hover .editIco{
    display: block;
 }
.editProfileModal .profileImgDiv .editIco input{
    display: none;
}
.editProfileModal .banner .editIco input{
    display: none;
}
.editProfileModal .imgText{
    font-size: 16px;
    margin-left: 190px;
    margin-top: -40px;
}
.editProfileModal .imgText p{
    margin: 0;
}
.modal-dialog {
    max-width: 700px;
}
.editProfileModal .eForm {
    padding: 0 20px;
}
.editProfileModal .eForm label{
    font-size: 20px;
    margin-bottom: 3px;
}
.editProfileModal .eForm .form-group{
    margin-bottom: 20px;
}
.editProfileModal .eForm .form-group input{
    background: transparent;
    font-size: 19px;
}
.editProfileModal .eForm .form-group textarea{
    background: transparent;
}
.editProfileModal .eForm .btnMain{
    display: flex;
    justify-content: space-between;
}
.editProfileModal .eForm .btnMain .Cancel{
    padding: 8px 30px;
    border: 1px solid #fff;
    border-radius: 8px;
    background: transparent;
    color: #fff;
    font-size: 19px;
}
.editProfileModal .eForm .btnMain .Cancel:hover{
    padding: 8px 30px;
    border: 1px solid #fff;
    border-radius: 8px;
    background: #000;
    color: #fff;
}

.editProfileModal .eForm .btnMain .save{
    padding: 8px 30px;
    border: 1px solid transparent;
    border-radius: 12px;
    color: #fff;
    font-size: 19px;
}
.editProfileModal .eForm .btnMain .Cancel:hover{
    border: 1px solid #000;
    background: transparent;
    color: black;
}
.editProfileModal h2 {
    padding: 10px 20px;
    padding-top: 15px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
}
.modal-body {
    padding: 0 !important;
}

/* edti prifile */
@media (max-width:576px) {
    .profilePage .userNavigation .links .link {
        font-size: 18px;
        margin-right: 20px;
    }
    .profilePage .userNavigation .offer {
        font-size: 16px;
    }
    .editProfileModal .imgText {
        margin: 0;
        text-align: center;
    }
}
.create-loader{
    border: 5px solid #f3f3f3;
    animation: spin 1s linear infinite;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: inline-block;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
.item-hidden-modal>div{
  color: white;
  border-radius: 14px;
  /* background-image: linear-gradient(to right, #152a45, #1f3f65, #152a45); */
  box-shadow:inset 0 0 20px grey;
  -moz-box-shadow:inset 0 0 20px grey;
  -webkit-box-shadow:inset 0 0 20px grey;
  background-color: #041426;
}

.understand-btn{
    background-color: transparent;
    border: none !important;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 12%);
    background-image: linear-gradient(to right,#29deda 0%,#45aef1 51%,#8562ea 100%) !important;
    border-radius: 18px;
    padding: 7px 12px;
    font-family: "Mulish";
    margin-bottom: 20px !important;

}

.item-hide-understand-btn{
  background-color: transparent;
  border: none !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 12%);
  background-image: linear-gradient(to right,#29deda 0%,#45aef1 51%,#8562ea 100%) !important;
  border-radius: 18px;
  padding: 7px 12px;
  font-family: "Mulish";
  margin-bottom: 20px !important;

}

.header-modal{
  justify-content: center;
}
.header-modal+div{
  text-align: center !important;

}
@media(max-width:440px){
  .header-modal+div{
    font-size: 12px !important;
  }
}
.item-hide-header-modal{
  justify-content: center;
}
.item-hide-header-modal+div{
  text-align: center !important;

}
@media(max-width:440px){
  .item-hide-header-modal+div{
    font-size: 12px !important;
  }
}

.tradeHistoryMain {
    padding: 60px 0;
    position: relative;
}
.tradeHistoryMain .effect1 {
    width: 1900px;
    position: absolute;
    bottom: -850px;
    left: -1020px;
    z-index: -1;
}
.tradeHistoryMain .effect2 {
    width: 1900px;
    position: absolute;
    bottom: -850px;
    right: -1020px;
    z-index: -1;
}
.tradeHistoryMain .filterSec {
    padding-top: 90px;
}
.tradeHistoryMain h1{
    font-size: 35px;
    margin-bottom: 40px;
}
.tradeHistoryMain .filterSec .filter {
    font-size: 20px;
}
@media(max-width:768px){
    .tradeHistoryMain .filMain {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

}

.create-header{
  font-size: 42px;
  font-weight: bolder;
}

/* #img-upload{
  cursor: pointer;
  border: thin dashed;
  color: grey;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 25px;
  border-radius: 12px;
  -webkit-filter: drop-shadow(0 0 2.5px rgba(19,19,19,.16));
  filter: drop-shadow(0 0 2.5px rgba(19,19,19,.16));
  background-image: radial-gradient(circle at center,#1f4169 0,#0f2135 100%);
} */

.card-img{
  border-radius: 25px;
}
.card{
  border-radius: 25px;
  border: none;
}
#title::-webkit-input-placeholder, .property-value::-webkit-input-placeholder, .property-key::-webkit-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white
}
#title:-ms-input-placeholder, .property-value:-ms-input-placeholder, .property-key:-ms-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white
}
#title::placeholder, .property-value::placeholder, .property-key::placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white
}
#title{
  background: none;
  border: none;
  caret-color: #fff;
  height: 40px;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 33px;
  color: #fff;
  font-family: "Mulish";
  width: 100%;
  box-shadow: inset 0 0 10px #58006a;
  -moz-box-shadow: inset 0 0 10px #58006a;
  -webkit-box-shadow: inset 0 0 10px #58006a;
  border-radius: 10px;
  background-color: #041426;
}
#custom-select-collection-categ,#select-collection{
  box-shadow:inset 0 0 10px #58006a;
  -moz-box-shadow:inset 0 0 10px #58006a;
  -webkit-box-shadow:inset 0 0 10px #58006a;
  height: 40px;
  border-radius: 10px;
  background-color: #041426;
  color: white;
  font-style: italic;  
  
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(/static/media/download.8126ded7.png),linear-gradient(#051427 100%,#051427 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .9em top 50%, 0 0;
  background-size: .65em auto, 100%;
  padding-left: 10px !important;
  border: none !important;
  cursor: pointer;
}

#custom-select-collection-categ:focus-visible,#select-collection:focus-visible{
  outline: 0px !important;
}

#descript::-webkit-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  color: white;
  font-weight: lighter;
  font-style: italic;
}

#descript:-ms-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  color: white;
  font-weight: lighter;
  font-style: italic;
}

#descript::placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  color: white;
  font-weight: lighter;
  font-style: italic;
}

.create-loader{
  border: 5px solid #f3f3f3;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: inline-block;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* #descript{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  height: 40px;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 33px;
  font-family: "Mulish";
  height: 150px;
  width: inherit;
  box-shadow:inset 0 0 10px grey;
  -moz-box-shadow:inset 0 0 10px grey;
  -webkit-box-shadow:inset 0 0 10px grey;
  border-radius: 10px;
  background-color: #041426;
} */

#title:focus, #descript:focus, .property-value:focus, .property-key:focus{
  outline: none;
}
.create-btn{
  -webkit-filter: drop-shadow(1.338px 1.486px 0.5px rgba(19,19,19,.17));
          filter: drop-shadow(1.338px 1.486px 0.5px rgba(19,19,19,.17));
  background: linear-gradient(45deg, #D279F5, #422E9B);
  border: none;
  color: #fff;
  display: inline-block;
  padding: 5px 30px;
  border-radius: 25px;
  font-size: 24px;
  max-width: 203px;
  width: 100%;
  /* -webkit-filter: drop-shadow(1.338px 1.486px .5px rgba(19,19,19,.17)); */
}
#royaltyfee{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  height: 40px;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 33px;
  color: #ffffff;
  font-family: "Mulish";
  width: 100%;
  box-shadow:inset 0 0 10px #58006a;
  -moz-box-shadow:inset 0 0 10px #58006a;
  -webkit-box-shadow:inset 0 0 10px #58006a;
  border-radius: 10px;
  background-color: #041426;
  padding-bottom: 6px;
}

#royaltyfee:focus, .social-link-input:focus{
  outline: none;
}

#royaltyfee::-webkit-input-placeholder, .social-link-input::-webkit-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white;
}

#royaltyfee:-ms-input-placeholder, .social-link-input:-ms-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white;
}

#royaltyfee::placeholder, .social-link-input::placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white;
}
.social-img{
  position: absolute;
  height: 98%;
  padding-right: 14px;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  top: 0px;
  /* left: 3px; */
  box-shadow:inset 0 0 10px #58006a;
  -moz-box-shadow:inset 0 0 10px #58006a;
  -webkit-box-shadow:inset 0 0 10px #58006a;
  border-radius: 10px;
}
.social-link-input{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  height: 40px;
  padding-left: 60px;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 33px;
  color: #ffffff;
  font-family: "Mulish";
  width: 100%;
  box-shadow:inset 0 0 10px #58006a;
  -moz-box-shadow:inset 0 0 10px #58006a;
  -webkit-box-shadow:inset 0 0 10px #58006a;
  border-radius: 10px;
  background-color: #041426;
  padding-bottom: 4px;
}
.show-more-btn{
  -webkit-filter: drop-shadow(1.338px 1.486px 0.5px rgba(19,19,19,.17));
          filter: drop-shadow(1.338px 1.486px 0.5px rgba(19,19,19,.17));
  background: linear-gradient(45deg, #D279F5, #422E9B);
  border: none;
  color: #fff;
  display: inline-block;
  padding: 5px 30px;
  border-radius: 25px;
  font-size: 24px;
}
.pic-name-addrs{
  display: flex;
}
.profile-pic-img{
  width: 77px;
  height: 77px;
  border-radius: 39px;
}
.name-address{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 12px;
  font-family: 'Mulish', sans-serif;
}
.username{
  font-size: 22px;
  letter-spacing: 0px;
  line-height: 21px;
  color: #000000;
  font-weight: 600;
}
.address{
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 21px;
  color: #ffffff;
  font-weight: 400;
}
.weird-img{
  max-width: 80%;
  border-radius: 10px;
  /* height: 400px !important; */
}
@media (max-width:992px){
  .weird-img{
    width: 100% !important;
    height: auto !important;
  }

}
/* @media (max-width:768px){
  .weird-img{
    height: auto !important;
  } */

/* } */
.user-desc{
  margin-top: 30px;
  font-size: 15px;
  letter-spacing: 0px;
  font-family: 'Mulish', sans-serif;
  line-height: 24px;
  color: #ffffff;
  font-weight: 400;
}
.social-detail {
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 33px;
  color: #ffffff;
  font-weight: 400;
  font-family: 'Mulish', sans-serif;
}
.social-detail > span{
  margin-left: 10px;
}
.social-detail > img {
  width: 30px;
}
.follow-btn{
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 33px;
  color: #ffffff;
  font-weight: 400;
  font-family: 'Mulish', sans-serif;
  width: 161px;
  height: 50px;
  border-radius: 25px;
  -webkit-filter: drop-shadow(0px 2px 0.5px rgba(19,19,19,0.17));
          filter: drop-shadow(0px 2px 0.5px rgba(19,19,19,0.17));
  background-image: linear-gradient(90deg, #17e9d9 0%, #4fa3e1 50%, #875fe9 100%);
  color: white;
  border: none;
  margin-top: 25px;
}
.follow-btn:focus{
  outline: none;
}
.share-msg-btn{
  display: inline-block;
  margin-left: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 33px;
  color: #ffffff;
  font-weight: 400;
  font-family: 'Mulish', sans-serif;
  border-radius: 25px;
  -webkit-filter: drop-shadow(0px 2px 0.5px rgba(19,19,19,0.17));
          filter: drop-shadow(0px 2px 0.5px rgba(19,19,19,0.17));
  background-image: linear-gradient(90deg, #17e9d9 0%, #4fa3e1 50%, #875fe9 100%);
  color: white;
  border: none;
  margin-top: 25px;
}
.profile-view-tabs{
  /* border-bottom: 1px solid linear-gradient(90deg, #17e9d9 0%, #4fa3e1 50%, #875fe9 100%); */
  /* border-bottom: 1px solid #17e9d9;; */
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 33px;
  color: #ffffff;
  font-weight: 400;
  font-family: 'Mulish', sans-serif;
  margin-top: 20px;
}
.profile-view-tabs > .nav-item > span {
  padding: 10px 5px;
}
.time-remaining{
  font-size: 21px;
  line-height: 29px;
  color: #12243a;
  font-weight: bold;
  font-family: "Gotham-Font-Navbar";
  text-align: center;
}
.profile-view-tabs > li > span{
  font-size: 18px;
}

.active{
  background:  linear-gradient(to right,#29deda 0%,#45aef1 51%,#8562ea 100%) left  bottom no-repeat; 
  background-size:100% 2px;
}
/* Navbar */

/* @font-face {
  font-family: "Gotham-Font-Navbar";
  src: local("Gotham-Font"),
    url('../../fonts/Gotham-Font/GothamMedium_1.ttf') format("truetype");
}
@font-face {
  font-family: "Gotham-Font-CollectableFont";
  src: local("Gotham-Font"),
    url('../../fonts/Gotham-Font/GothamBook.ttf') format("truetype");
} */

#custom-select-edit-cat{
  box-shadow:inset 0 0 10px grey;
  -moz-box-shadow:inset 0 0 10px grey;
  -webkit-box-shadow:inset 0 0 10px grey;
  height: 40px;
  border-radius: 10px;
  background-color: #041426;
  color: white;
  font-style: italic;    
}

.add-category-btn{
  border-radius: 25px;
  -webkit-filter: drop-shadow(1.338px 1.486px 0.5px rgba(19,19,19,0.17));
          filter: drop-shadow(1.338px 1.486px 0.5px rgba(19,19,19,0.17));
  background-image: linear-gradient(90deg, #17e9d9 0%, #4fa3e1 50%, #875fe9 100%);
  border: none;
  color: white;
  display: inline-block;
  padding-right: 20px;
  padding-left: 25px;
  border-radius: 25px;
}
.add-category-btn:focus,.add-category-btn:focus-visible{
  outline: 0px !important;
}
#partner-upload{
  cursor: pointer;
  border: thin dashed;
  color: grey;
  /* text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 50%; */

  /* filter: drop-shadow(0 0 2.5px rgba(19,19,19,0.16)); */
  /* background-image: radial-gradient(circle at center, #1f4169 0%, #0f2135 100%); */

  /* padding-left: 31px; */
  background: rgba(15, 28, 63, 0.125);
  border-radius: 25px;
  height: 12em;
  object-fit: cover;
  width: 100%;
  /* padding-top: 38px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
#categ-upload,#weekly-collection-upload{
  cursor: pointer;
  border: thin dashed;
  color: grey;
  background: rgba(15, 28, 63, 0.125);
  border-radius: 50%;
  height: 9em;
  object-fit: cover;
  width: 9em;
  display: flex;
  justify-content: center;
  align-items: center;
}
#edit-categ-upload, #edit-partner-upload{
  cursor: pointer;
  border: thin dashed;
  color: grey;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 12px;

  /* filter: drop-shadow(0 0 2.5px rgba(19,19,19,0.16)); */
  /* background-image: radial-gradient(circle at center, #1f4169 0%, #0f2135 100%); */
}

.admin-category-table {
  width: 100%;
  /* border: 1px solid #eee; */
  font-size:19px;
  z-index:1
}
@media (max-width:410px){
.admin-category-table{
display: block;
    width: 100%;
    overflow-x: auto;
}
}
/* .table {
  width: 100%;
  border: 1px solid #eee;
}
.table-header {
  display: flex;
  width: 100%;
  background: #000;
  padding: 18px 0;
}
.table-row {
  display: flex;
  width: 100%;
  padding: 18px 0;
}
.table-row:nth-of-type(odd) {
  background: #eee;
}
.table-data, .header__item {
  flex: 1 1 20%;
  text-align: center;
}
.header__item {
  text-transform: uppercase;
}
.filter__link {
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding-left: 24px;
  padding-right: 24px;
}
.filter__link::after {
  content: '';
  position: absolute;
  right: -18px;
  color: white;
  font-size: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.filter__link.desc::after {
  content: '(desc)';
}
.filter__link.asc::after {
  content: '(asc)';
} */
#title::-webkit-input-placeholder, #adminid::-webkit-input-placeholder, #adminpwd::-webkit-input-placeholder{
  color:white !important
}
#title:-ms-input-placeholder, #adminid:-ms-input-placeholder, #adminpwd:-ms-input-placeholder{
  color:white !important
}
#title::placeholder, #adminid::placeholder, #adminpwd::placeholder{
  color:white !important
}
#adminid, #adminpwd{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  height: 40px;
  padding-bottom: 4px;
  padding-top: 1px;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0px;
  /* line-height: 33px; */
  color: #ffffff;
  font-family: "Mulish";
  width: inherit;
  box-shadow:inset 0 0 10px grey;
  -moz-box-shadow:inset 0 0 10px grey;
  -webkit-box-shadow:inset 0 0 10px grey;
  border-radius: 10px;
  background-color: #041426;
}
#adminid:focus, #adminpwd:focus,.collectionFilter:focus{
  outline: none;
}

#adminid::-webkit-input-placeholder, #adminpwd::-webkit-input-placeholder, #title::-webkit-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  color: white;
  font-weight: lighter;
  font-style: italic;
}

#adminid:-ms-input-placeholder, #adminpwd:-ms-input-placeholder, #title:-ms-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  color: white;
  font-weight: lighter;
  font-style: italic;
}

#adminid::placeholder, #adminpwd::placeholder,#title::placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  color: white;
  font-weight: lighter;
  font-style: italic;
}


#title{
  padding-bottom:6px !important
}
#token_img_add{
  cursor: pointer;
  border: thin dashed;
  color: grey;
  /* text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 50%;
  width: 150px; */
  /* margin-left: auto;
  margin-right: auto; */
  /* filter: drop-shadow(0 0 2.5px rgba(19,19,19,0.16));
  background-image: radial-gradient(circle at center, #1f4169 0%, #0f2135 100%); */


  
  padding-left: 31px;
  background: rgba(15, 28, 63, 0.125);
  border-radius: 50%;
  height: 9em;
  object-fit: cover;
  width: 9em;
  padding-top: 38px;
}

#addtok_address,#addtok_name,#addtok_symbol,#addtok_decimals,.collectionFilter,#partner-name,#weekly-collection-name, #exchange_url{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  height: 40px;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0px;
  /* line-height: 33px; */
  padding-top: 1px;
  padding-bottom: 4px;
  color: #ffffff;
  font-family: "Mulish";
  /* width: inherit; */
  box-shadow:inset 0 0 10px grey;
  -moz-box-shadow:inset 0 0 10px grey;
  -webkit-box-shadow:inset 0 0 10px grey;
  border-radius: 10px;
  background-color: #041426;
}

#addtok_address::-webkit-input-placeholder, #addtok_name::-webkit-input-placeholder, #addtok_symbol::-webkit-input-placeholder, #addtok_decimals::-webkit-input-placeholder, .collectionFilter::-webkit-input-placeholder, #partner-name::-webkit-input-placeholder, #partner-des::-webkit-input-placeholder, #weekly-collection-des::-webkit-input-placeholder, #weekly-collection-name::-webkit-input-placeholder, #exchange_url::-webkit-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white
}

#addtok_address:-ms-input-placeholder, #addtok_name:-ms-input-placeholder, #addtok_symbol:-ms-input-placeholder, #addtok_decimals:-ms-input-placeholder, .collectionFilter:-ms-input-placeholder, #partner-name:-ms-input-placeholder, #partner-des:-ms-input-placeholder, #weekly-collection-des:-ms-input-placeholder, #weekly-collection-name:-ms-input-placeholder, #exchange_url:-ms-input-placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white
}

#addtok_address::placeholder,#addtok_name::placeholder,#addtok_symbol::placeholder,#addtok_decimals::placeholder,.collectionFilter::placeholder,#partner-name::placeholder,#partner-des::placeholder,#weekly-collection-des::placeholder,#weekly-collection-name::placeholder, #exchange_url::placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white
}
#partner-des,#weekly-collection-des{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0px;
  /* line-height: 33px; */
  padding-top: 10px;
  padding-bottom: 4px;
  color: #ffffff;
  font-family: "Mulish";
  /* width: inherit; */
  box-shadow:inset 0 0 10px grey;
  -moz-box-shadow:inset 0 0 10px grey;
  -webkit-box-shadow:inset 0 0 10px grey;
  border-radius: 10px;
  background-color: #041426;

}
.item-hidden-modal>div{
  color: white;
  border-radius: 14px;
  /* background-image: linear-gradient(to right, #152a45, #1f3f65, #152a45); */
  box-shadow:inset 0 0 20px grey;
  -moz-box-shadow:inset 0 0 20px grey;
  -webkit-box-shadow:inset 0 0 20px grey;
  background-color: #041426;
}

.understand-btn{
    background-color: transparent;
    border: none !important;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 12%);
    background-image: linear-gradient(to right,#29deda 0%,#45aef1 51%,#8562ea 100%) !important;
    border-radius: 18px;
    padding: 7px 12px;
    font-family: "Mulish";
    margin-bottom: 20px !important;

}

.item-hide-understand-btn{
  background-color: transparent;
  border: none !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 12%);
  background-image: linear-gradient(to right,#29deda 0%,#45aef1 51%,#8562ea 100%) !important;
  border-radius: 18px;
  padding: 7px 12px;
  font-family: "Mulish";
  margin-bottom: 20px !important;

}

.header-modal{
  justify-content: center;
}
.header-modal+div{
  text-align: center !important;

}
@media(max-width:440px){
  .header-modal+div{
    font-size: 12px !important;
  }
}
.item-hide-header-modal{
  justify-content: center;
}
.item-hide-header-modal+div{
  text-align: center !important;

}
@media(max-width:440px){
  .item-hide-header-modal+div{
    font-size: 12px !important;
  }
}

