.show-more-btn{
  filter: drop-shadow(1.338px 1.486px 0.5px rgba(19,19,19,.17));
  background: linear-gradient(45deg, #D279F5, #422E9B);
  border: none;
  color: #fff;
  display: inline-block;
  padding: 5px 30px;
  border-radius: 25px;
  font-size: 24px;
}
.pic-name-addrs{
  display: flex;
}
.profile-pic-img{
  width: 77px;
  height: 77px;
  border-radius: 39px;
}
.name-address{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 12px;
  font-family: 'Mulish', sans-serif;
}
.username{
  font-size: 22px;
  letter-spacing: 0px;
  line-height: 21px;
  color: #000000;
  font-weight: 600;
}
.address{
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 21px;
  color: #ffffff;
  font-weight: 400;
}
.weird-img{
  max-width: 80%;
  border-radius: 10px;
  /* height: 400px !important; */
}
@media (max-width:992px){
  .weird-img{
    width: 100% !important;
    height: auto !important;
  }

}
/* @media (max-width:768px){
  .weird-img{
    height: auto !important;
  } */

/* } */
.user-desc{
  margin-top: 30px;
  font-size: 15px;
  letter-spacing: 0px;
  font-family: 'Mulish', sans-serif;
  line-height: 24px;
  color: #ffffff;
  font-weight: 400;
}
.social-detail {
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 33px;
  color: #ffffff;
  font-weight: 400;
  font-family: 'Mulish', sans-serif;
}
.social-detail > span{
  margin-left: 10px;
}
.social-detail > img {
  width: 30px;
}
.follow-btn{
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 33px;
  color: #ffffff;
  font-weight: 400;
  font-family: 'Mulish', sans-serif;
  width: 161px;
  height: 50px;
  border-radius: 25px;
  filter: drop-shadow(0px 2px 0.5px rgba(19,19,19,0.17));
  background-image: linear-gradient(90deg, #17e9d9 0%, #4fa3e1 50%, #875fe9 100%);
  color: white;
  border: none;
  margin-top: 25px;
}
.follow-btn:focus{
  outline: none;
}
.share-msg-btn{
  display: inline-block;
  margin-left: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 33px;
  color: #ffffff;
  font-weight: 400;
  font-family: 'Mulish', sans-serif;
  border-radius: 25px;
  filter: drop-shadow(0px 2px 0.5px rgba(19,19,19,0.17));
  background-image: linear-gradient(90deg, #17e9d9 0%, #4fa3e1 50%, #875fe9 100%);
  color: white;
  border: none;
  margin-top: 25px;
}
.profile-view-tabs{
  /* border-bottom: 1px solid linear-gradient(90deg, #17e9d9 0%, #4fa3e1 50%, #875fe9 100%); */
  /* border-bottom: 1px solid #17e9d9;; */
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 33px;
  color: #ffffff;
  font-weight: 400;
  font-family: 'Mulish', sans-serif;
  margin-top: 20px;
}
.profile-view-tabs > .nav-item > span {
  padding: 10px 5px;
}
.time-remaining{
  font-size: 21px;
  line-height: 29px;
  color: #12243a;
  font-weight: bold;
  font-family: "Gotham-Font-Navbar";
  text-align: center;
}
.profile-view-tabs > li > span{
  font-size: 18px;
}

.active{
  background:  linear-gradient(to right,#29deda 0%,#45aef1 51%,#8562ea 100%) left  bottom no-repeat; 
  background-size:100% 2px;
}