/* Navbar */
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

/* @font-face {
  font-family: "Gotham-Font-Navbar";
  src: local("Gotham-Font"),
    url('../../fonts/Gotham-Font/GothamMedium_1.ttf') format("truetype");
}
@font-face {
  font-family: "Gotham-Font-CollectableFont";
  src: local("Gotham-Font"),
    url('../../fonts/Gotham-Font/GothamBook.ttf') format("truetype");
} */

#custom-select-edit-cat{
  box-shadow:inset 0 0 10px grey;
  -moz-box-shadow:inset 0 0 10px grey;
  -webkit-box-shadow:inset 0 0 10px grey;
  height: 40px;
  border-radius: 10px;
  background-color: #041426;
  color: white;
  font-style: italic;    
}

.add-category-btn{
  border-radius: 25px;
  filter: drop-shadow(1.338px 1.486px 0.5px rgba(19,19,19,0.17));
  background-image: linear-gradient(90deg, #17e9d9 0%, #4fa3e1 50%, #875fe9 100%);
  border: none;
  color: white;
  display: inline-block;
  padding-right: 20px;
  padding-left: 25px;
  border-radius: 25px;
}
.add-category-btn:focus,.add-category-btn:focus-visible{
  outline: 0px !important;
}
#partner-upload{
  cursor: pointer;
  border: thin dashed;
  color: grey;
  /* text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 50%; */

  /* filter: drop-shadow(0 0 2.5px rgba(19,19,19,0.16)); */
  /* background-image: radial-gradient(circle at center, #1f4169 0%, #0f2135 100%); */

  /* padding-left: 31px; */
  background: rgba(15, 28, 63, 0.125);
  border-radius: 25px;
  height: 12em;
  object-fit: cover;
  width: 100%;
  /* padding-top: 38px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
#categ-upload,#weekly-collection-upload{
  cursor: pointer;
  border: thin dashed;
  color: grey;
  background: rgba(15, 28, 63, 0.125);
  border-radius: 50%;
  height: 9em;
  object-fit: cover;
  width: 9em;
  display: flex;
  justify-content: center;
  align-items: center;
}
#edit-categ-upload, #edit-partner-upload{
  cursor: pointer;
  border: thin dashed;
  color: grey;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 12px;

  /* filter: drop-shadow(0 0 2.5px rgba(19,19,19,0.16)); */
  /* background-image: radial-gradient(circle at center, #1f4169 0%, #0f2135 100%); */
}

.admin-category-table {
  width: 100%;
  /* border: 1px solid #eee; */
  font-size:19px;
  z-index:1
}
@media (max-width:410px){
.admin-category-table{
display: block;
    width: 100%;
    overflow-x: auto;
}
}
/* .table {
  width: 100%;
  border: 1px solid #eee;
}
.table-header {
  display: flex;
  width: 100%;
  background: #000;
  padding: 18px 0;
}
.table-row {
  display: flex;
  width: 100%;
  padding: 18px 0;
}
.table-row:nth-of-type(odd) {
  background: #eee;
}
.table-data, .header__item {
  flex: 1 1 20%;
  text-align: center;
}
.header__item {
  text-transform: uppercase;
}
.filter__link {
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding-left: 24px;
  padding-right: 24px;
}
.filter__link::after {
  content: '';
  position: absolute;
  right: -18px;
  color: white;
  font-size: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.filter__link.desc::after {
  content: '(desc)';
}
.filter__link.asc::after {
  content: '(asc)';
} */
#title::placeholder, #adminid::placeholder, #adminpwd::placeholder{
  color:white !important
}
#adminid, #adminpwd{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  height: 40px;
  padding-bottom: 4px;
  padding-top: 1px;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0px;
  /* line-height: 33px; */
  color: #ffffff;
  font-family: "Mulish";
  width: inherit;
  box-shadow:inset 0 0 10px grey;
  -moz-box-shadow:inset 0 0 10px grey;
  -webkit-box-shadow:inset 0 0 10px grey;
  border-radius: 10px;
  background-color: #041426;
}
#adminid:focus, #adminpwd:focus,.collectionFilter:focus{
  outline: none;
}

#adminid::placeholder, #adminpwd::placeholder,#title::placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  color: white;
  font-weight: lighter;
  font-style: italic;
}


#title{
  padding-bottom:6px !important
}
#token_img_add{
  cursor: pointer;
  border: thin dashed;
  color: grey;
  /* text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 50%;
  width: 150px; */
  /* margin-left: auto;
  margin-right: auto; */
  /* filter: drop-shadow(0 0 2.5px rgba(19,19,19,0.16));
  background-image: radial-gradient(circle at center, #1f4169 0%, #0f2135 100%); */


  
  padding-left: 31px;
  background: rgba(15, 28, 63, 0.125);
  border-radius: 50%;
  height: 9em;
  object-fit: cover;
  width: 9em;
  padding-top: 38px;
}

#addtok_address,#addtok_name,#addtok_symbol,#addtok_decimals,.collectionFilter,#partner-name,#weekly-collection-name, #exchange_url{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  height: 40px;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0px;
  /* line-height: 33px; */
  padding-top: 1px;
  padding-bottom: 4px;
  color: #ffffff;
  font-family: "Mulish";
  /* width: inherit; */
  box-shadow:inset 0 0 10px grey;
  -moz-box-shadow:inset 0 0 10px grey;
  -webkit-box-shadow:inset 0 0 10px grey;
  border-radius: 10px;
  background-color: #041426;
}

#addtok_address::placeholder,#addtok_name::placeholder,#addtok_symbol::placeholder,#addtok_decimals::placeholder,.collectionFilter::placeholder,#partner-name::placeholder,#partner-des::placeholder,#weekly-collection-des::placeholder,#weekly-collection-name::placeholder, #exchange_url::placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white
}
#partner-des,#weekly-collection-des{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0px;
  /* line-height: 33px; */
  padding-top: 10px;
  padding-bottom: 4px;
  color: #ffffff;
  font-family: "Mulish";
  /* width: inherit; */
  box-shadow:inset 0 0 10px grey;
  -moz-box-shadow:inset 0 0 10px grey;
  -webkit-box-shadow:inset 0 0 10px grey;
  border-radius: 10px;
  background-color: #041426;

}