.tradeHistoryMain {
    padding: 60px 0;
    position: relative;
}
.tradeHistoryMain .effect1 {
    width: 1900px;
    position: absolute;
    bottom: -850px;
    left: -1020px;
    z-index: -1;
}
.tradeHistoryMain .effect2 {
    width: 1900px;
    position: absolute;
    bottom: -850px;
    right: -1020px;
    z-index: -1;
}
.tradeHistoryMain .filterSec {
    padding-top: 90px;
}
.tradeHistoryMain h1{
    font-size: 35px;
    margin-bottom: 40px;
}
.tradeHistoryMain .filterSec .filter {
    font-size: 20px;
}
@media(max-width:768px){
    .tradeHistoryMain .filMain {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

}
