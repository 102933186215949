.p-cont
{
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
}
.p-details
{
    /* width: 100%; */
}
.product-1
{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.product-1 p {
    font-size: 19px;
}
.product-2 {
    background: rgba(255, 255,255, 0.1);
    border-radius: 10px;
    /* border: 1px solid #C8C7C7; */
    padding: 25px;
}
.p2-price {
    margin-bottom: 0px;
    font-size: 19px;
}
.p2-nums
{
    font-size: 40px;
    /* padding-top: 30px;
    padding-left: 20px; */
}
.p2-percent
{
    margin-top: -10px;
    /* padding-left: 20px; */
    font-size: 19px;
}
.productDetail .chartMain {
    
    margin-top: 50px;
}
.productDetail .chartMain .chartM{
    padding: 20px;
    background: rgba(255,255,255,0.1);
    border-radius: 12px;
    margin-top:0px;
}
.productDetail p {
    font-size: 19px;
}
.productDetail .userLogo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
}
.productDetail .userLogo img{
    width: 100%;
}
.themeBtn {
    border: none;
    border-radius: 8px;
    /* background: linear-gradient(45deg, #D279F5, #422E9B); */
    background: linear-gradient(45deg, #D279F5 0%, #422E9B 50%);
    background-size: 200%;
    transition: all 0.3s;
}
.themeBtn:hover {
    opacity: 0.8;
    background-position: 99%;
    transition: all 0.3s;
}
.p2-btn {
    border: none;
    border-radius: 8px;
    background-color: black;
    background: linear-gradient(45deg, #D279F5, #422E9B);
    height: 55px;
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    margin-top: 30px;
}
.p-ins
{
    /* height: 30px; */
display: flex;
justify-content: flex-end;
}
.likes
{
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    font-size: 20px;
}
.likes i
{
    color: #C8C7C7;
    
}
.shares
{
    padding: 8px 12px;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    margin-left: 6px;
}
.shares img {
    height: 30px;
}
.option
{
    padding: 8px 12px;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-left: 6px;
}

.product-3
{
    display: flex;
    flex-direction: row;
    margin: 40px 0;
}
.pic
{
    width: 50px;
    height: 100%;
}
.ob
{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.pic{
    border-radius: 50px;
}
.p-img
{
    border: 1px solid black;
}
.imgcont
{
    height: 100%;
}
.product-img
{
    /* max-width: 100%; */
   max-width: 100%;
   /* height: 100%; */

}
.tblcont
{
    /* width: 100%; */

}
.tblheadings
{
    display: flex;
    justify-content: space-evenly;
}
.tblcontent
{
    display: flex;
    justify-content: space-evenly;
}
.tblcontent-cont
{
    /* border: 1px solid #C8C7C7; */
    border-radius: 10px;
    background: rgba(255, 255,255, 0.1);
}
.tblcontent-cont .profileDiv {
    display: flex;
    align-items: center;
    min-width: 200px;
}
.tblcontent-cont .profileDiv .logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 7px;
}
.tblcontent-cont .profileDiv .price .bath {
    font-size: 23px;
}
.tblcontent-cont .profileDiv .price .dollar {
    font-size: 17px;
}

.tblcontent-cont .itemDiv {
    display: flex;
    align-items: center;
    min-width: 200px;

}
.tblcontent-cont .itemDiv .logo {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 12px;
}
.tblcontent-cont .itemDiv .price .bath {
    font-size: 20px;
}
.tblcontent-cont .itemDiv .price .dollar {
    font-size: 14px;
}
.tblcontent-cont table tbody {
    border: none !important;
}
.tblcontent-cont table thead {
    border: 1px;
    border-color: rgba(255, 255,255, 0.2);
}
.tblcontent-cont table tr td {
    vertical-align: middle;
    padding: 10px;
    border-color: rgba(255, 255,255, 0.2);
    color: white;
    font-size: 19px;
    
}
.productDetail .tradeHistory {
    padding-top: 50px;
}
.productDetail .thisColectionSec {
    padding-top: 50px;
}
.productDetail .thisColectionSec .cName{
    font-size: 24px;
    margin-bottom: 30px;
}
.price
{
    /* display: flex; */

}
.bicon
{
    margin-top: 5px;
    height: 80%;
}
.bath999
{
margin-top: 5px;
/* margin-right: 5px; */
}
.expiration
{
    /* margin-left: -30px; */
}
.mkof
{
    font-size: 23px;
    border-radius: 10px;
    background-color: black;
    color: white;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 7px;
}
.up 
{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}
.down
{
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
}
.productDetail .propertiesCard {
    background-color: rgba(255, 255,255, 0.1);
    /* border: 1px solid #C8C7C7; */
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    width: 48%;
    margin-bottom: 15px;
}
.productDetail .propertiesCard .property {
    font-size: 20px;
   
}
.productDetail .propertiesCard .value {
    font-size: 24px;
   
}
.productDetail .propertiesCard .perc {
    font-size: 16px;
   
}
.g1
{

/* margin-left: 5px; */
/* margin-right: 10px; */
}
.g2
{
    width: 100%;
border-radius: 10px;
}
.g3
{height: 140px;
    width: 100%;
    border-radius: 10px;
    margin-right: 10px;
}
.g4
{height: 140px;
    border-radius: 10px;
    width: 100%;
}
.det-head
{
    margin-top: 10px;
}
.productDetail {
    padding: 70px 0;
    position: relative;
    overflow: hidden;
}
.productDetail .effect1 {
    width: 1900px;
    position: absolute;
    top: -850px;
    right: -1020px;
    z-index: -1;
  }
.productDetail .effect2 {
    width: 1800px;
    position: absolute;
    top: 790px;
    right: -1020px;
    z-index: -1;
  }
.productDetail .effect3 {
    width: 1800px;
    position: absolute;
    top: 990px;
    left: -1020px;
    z-index: -1;
  }

.editProfileModal .closeDiv {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
.editProfileModal .purchaseMain .check{
    max-width: 70px;
    margin: auto;
    margin-bottom: 20px;
}
.editProfileModal .purchaseMain{
    padding: 40px;
    text-align: center;
}
.editProfileModal .purchaseMain .purchase{
    font-size: 35px;
}
.editProfileModal .purchaseMain .desc{
    font-size: 19px;
}

.editProfileModal .checkForm .r1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 23px;
    margin-top: 20px;
}
.color-white {
    color: white !important;
}
.editProfileModal .checkForm .r1 p{
    font-size: 23px;
    font-weight: 500;
}
.editProfileModal .checkForm .checkCArd {
    background: #fff;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    color: #000;
    border-radius: 20px;
}   
.editProfileModal .checkForm .profileDiv {
    display: flex;
    align-items: center;
}
.editProfileModal .checkForm .profileDiv .logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 7px;
}
.editProfileModal .checkForm .profileDiv .price .bath {
    font-size: 23px;
    color: #000;
}
.editProfileModal .checkForm .profileDiv .price .dollar {
    font-size: 17px;
    color: #000;
}

.editProfileModal .checkForm .itemDiv {
    display: flex;
    align-items: center;
    min-width: 200px;

}
.editProfileModal .checkForm .itemDiv .logo {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 12px;
}
.editProfileModal .checkForm .itemDiv .price .bath {
    font-size: 23px;
    color: #000;
}
.editProfileModal .checkForm .itemDiv .price .dollar {
    font-size: 17px;
    color: #000;
}
.shareLinkIco {
    width: 40px;
    cursor: pointer;
}
.shareLinkIco svg path,.shareLinkIco svg  {
    fill: #fff;
    stroke: #fff;
}
.shareLinkIco.copy svg path,.shareLinkIco.copy svg  {
    fill: green;
    stroke: green;
}
@media (max-width: 992px) {
    .imgcont {
        margin-bottom: 20px;
        text-align: center;
    }
    .shares img {
        width: 24px;
    }
}
.create-loader{
    border: 5px solid #f3f3f3;
    animation: spin 1s linear infinite;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: inline-block;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.iconSocial {
    width: 23px;
}
