@font-face {
  font-family: MadeBonVoyage;
  src: url('../../Fonts/made_bon_voyage_regular.otf');
}
@font-face {
  font-family: DbGillSiam;
  src: url('../../Fonts/DB\ Gill\ Siam\ X\ Li\ v3.2.ttf');
}
  
  .marketPlaceMain {
    position: relative;
    padding: 70px 0;
    padding-top: 0;
  }
  .marketPlaceMain .effect1 {
    width: 1900px;
    position: absolute;
    top: -850px;
    right: -1020px;
    z-index: -1;
  }

.marketPlaceMain .effect2 {
  width: 1900px;
  position: absolute;
  bottom: -850px;
  left: -1020px;
  z-index: -1;
}
  .marketDetail  {
  }
  .marketPlaceMain .dataOverviewMain {
    color: white;
    display: flex;
    max-width: 500px;
    margin: auto;
    /* font-size: 13px; */
    justify-content: space-between;
    padding: 15px;
    border: 2px solid #203854;
    border-radius: 10px;
    margin-top: 250px;
    backdrop-filter: blur(8px);
    background: rgba(0,0,0,0.4);
  }
  .marketPlaceMain .dataOverviewMain .data {
    text-align: center;
  }
  .marketPlaceMain .dataOverviewMain .data h4{
    margin: 0;
    font-size: 13px;
  }
  .marketPlaceMain .dataOverviewMain .data p{
    font-size: 13px;
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
  }
  .marketPlaceMain .marketMain {
    /* border: 2px solid #203854;
    padding: 8px; */
    /* border-radius: 10px; */
    margin-top: 50px;
  }
  .marketPlaceMain .filSidebar .inputDiv{
    padding-bottom: 10px;
    display: flex;
  }
  .marketPlaceMain .filSidebar .inputDiv input::placeholder{
    color: #fff;
    font-weight: 500;
  }
  .marketPlaceMain .filSidebar .inputDiv input{
    background: transparent;
    border: 1px solid #ACAEB1;
    border-radius: 6px;
    color: #fff;
    font-size: 18px;
  }
  .marketPlaceMain .filSidebar .price{
    font-size: 15px;
  }
  .marketPlaceMain .filSidebar .applyBtn {
    padding-bottom: 10px;
  }
  .marketPlaceMain .filSidebar .applyBtn button{
    background: rgba(255,255,255,0.1);
    color: white;
    padding: 5px;
    width: 100%;
    font-weight: 500;
    border-radius: 8px;
    border: none;
    font-size: 20px;
  }
  .marketPlaceMain .filSidebar{
    /* height: 100%; */
    background: transparent;
    /* padding: 10px; */
    /* box-shadow: inset 0 0 30px #202d3d; */
    border-radius: 15px;
  }
  .marketPlaceMain .filterMain {
    padding: 20px;
    padding-left: 0;
  }
  .marketPlaceMain .marketMain .itemBtnDiv {
    text-align: center;
    margin-bottom: 10px;
  }
  .marketPlaceMain .marketMain .itemBtnDiv button{
    border: 2px solid #203854;
    padding: 8px 20px;
    border-radius: 10px;
    /* color: white; */
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .marketPlaceMain .marketMain .dropDownCard .card-body{
    padding: 0;
  }
  .marketPlaceMain .marketMain .dropDownCard {
    /* border: 2px solid #384f6a; */
    border-radius: 0;
  }
  .marketPlaceMain .marketMain .dropDownCard.heading {
    border-bottom: 1px solid #ACAEB1;
  }
  .marketPlaceMain .marketMain .dropDownCard>div{
    /* border-top: 1px solid #203854; */
    padding-top: 5px;
  }
  .marketPlaceMain .marketMain .title {
    color: #fff;
    font-weight: normal;
    text-transform: capitalize;
    font-size: 20px;
    width: 100%;
    text-align: left;
    /* border: 2px solid #203854; */
    padding: 10px 0px;
    border-radius: 10px;
  }
  .font-bold {
    font-weight: 500 !important;
  }
  .marketPlaceMain .marketMain .dropDownCard .contentMain {
    max-height: 150px;
    overflow-y: auto;
    /* margin-bottom: 10px; */
  }
  .marketPlaceMain .marketMain .dropDownCard .contentMain .selectContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: transparent;
    color: white;
    border: none;
  }
  .marketPlaceMain .marketMain .dropDownCard .contentMain .selectContent span{
    font-size: 12px;
    font-weight: bold;
  }
  .marketPlaceMain .marketMain .dropDownCard .card-body input{
    /* background: transparent;
    border: 1px solid #6c757d;
    border-left: 1px solid transparent;
    color: white;
    padding-left: 0; */
  }
  .marketPlaceMain .marketMain .dropDownCard .card-body input:focus{
    box-shadow: none;
  }
  .marketPlaceMain .marketMain .dropDownCard .card-body .searchBtn{
    border-right: 1px solid transparent;
  }
  .marketPlaceMain ::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }
  .marketPlaceMain  {
    scrollbar-width: thin;
    /* scrollbar-color: #90A4AE #CFD8DC; */
  }
  .marketPlaceMain ::-webkit-scrollbar-track {
    background: #CFD8DC;
    border-radius: 10px ;
  }
  .marketPlaceMain ::-webkit-scrollbar-thumb {
    background-color: #90A4AE ;
    border-radius: 6px;
    /* border: 3px solid #CFD8DC; */
  }
  .marketPlaceMain button:focus{
    box-shadow: none;
  }
  .marketPlaceMain .marketMain .sortByMain .btn{
    width: 200px;
    border: 1px solid #231F20;
    font-size: 19px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255,255,255,0.1);
    color: white;
  }
  .marketPlaceMain .marketMain .sortByMain .dropdown-toggle::after {
    display: none;
  }
  .marketPlaceMain .marketMain .sortByMain .number p{
    font-size: 25px;
  }
  .marketPlaceMain .marketMain .sortByMain .number h1{
    margin-bottom: 0;
    font-size: 35px;
    font-weight: 700;
  }
  .marketPlaceMain .marketMain .sortByMain .dropdown-menu{
    background: rgb(31 36 62);
    box-shadow: inset 0 0 30px #202d3d;
    width: 100%;
  }
  .marketPlaceMain .marketMain .sortByMain .dropdown-item{
    margin-left: 0;
    color: #fff;
  }
  .marketPlaceMain .marketMain .sortByMain .dropdown-item:hover{
    background: #233f61;
    margin-left: 0;
    color: #fff;
  }
  .marketPlaceMain .marketMain .sortByMain{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
  }
  .marketPlaceMain .marketMain .sortByMain img{
    width: 18px;
  }
  .marketPlaceMain .marketMain .priceMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
  }
  .marketPlaceMain .marketMain .priceMain .col1{
    display: flex;
    flex-wrap: wrap;
    color: white;
    text-transform: uppercase;
  }
  .marketPlaceMain .marketMain .priceMain .col1 span {
    padding: 7px 15px;
    border-radius: 10px;
    border: 2px solid #203854;
    font-size: 13px;
    margin-right: 10px;
    cursor: pointer;
  }
  .marketPlaceMain .marketMain .priceMain .col1 p{
    font-size: 12px;
    margin-bottom: 0;
  }
  .marketPlaceMain .marketMain .priceMain .col1 .item{
    margin-right: 20px;
  }
  .marketPlaceMain .marketMain .priceMain .col2 .dropdown-toggle:hover{
    color: white;
  }
  .marketPlaceMain .marketMain .priceMain .col2 .dropdown-menu{
    background-color: transparent;
    left:unset !important;
    right: 0 !important;
  }
  .marketPlaceMain .themeBtn {
    border: 2px solid #203854;
    padding: 8px 20px;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard {
    border: 2px solid #203854;
    /* padding: 10px; */
    border-radius: 10px;
    color: white;
    font-size: 10px;
    height: 280px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
  .marketPlaceMain .marketMain .nftCardMain .nftCard:hover{
    border: 2px solid #ffffff;
    color: #203854 !important;
    box-shadow: 2px 2px 2px #ffffff;
  }
  .bg-Dark {
    background-color: #1a0e28 !important;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .imgDiv{
    height: 74%;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-bottom: 1px solid #203854;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .imgDiv img{
    min-width: 100%;
    max-width: 100%;
    height: auto;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain{
    padding: 10px;
    height: 26%;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .id{
    margin: 0;
    font-weight: bold;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .contentDiv{
    display: flex;
    justify-content: space-between;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .contentDiv p{
    margin: 0;
    text-transform: uppercase;
    margin: 4px 0;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .contentDiv .amount{
    font-weight: bold;
  }
  .marketPlaceMain .marketMain .nftCardMain .nftCard .infoMain .contentDiv .amount img{
    height: 14px;
  }
  
  .marketDetail {
    margin: 10px 0;
  }
  .marketDetail .mainImgDiv {
    border: 2px solid #203854;
    /* padding: 10px; */
    border-radius: 10px;
    overflow: hidden;
  }
  .marketDetail .mainImgDiv img{
    width: 100%;
    height: auto;
    border-radius: 7px;
  }
  .marketDetail .themeBorder {
    border: 2px solid #203854;
    border-radius: 10px;
  }
  .marketDetail .nftNameMain {
    color: white;
    padding: 18px;
  }
  .marketDetail .nftNameMain h6{
    text-transform: uppercase;
    font-size: 15px;
  }
  .marketDetail .nftNameMain .address{
    
  }
  .marketDetail .nftNameMain .address span{
    font-size: 15px;
  }
  .marketDetail .nftNameMain .address img{
    width: 33px;
    height: 33px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .marketDetail .buyMain {
    color: white;
    padding: 18px;
  }
  .marketDetail .buyMain .col1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .marketDetail .buyMain .col1 h6{
    margin-bottom: 0;
  }
  .marketDetail .buyMain .col1 .mLink{
    color: white;
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 14px;
    display: inline-block;
  }
  .marketDetail .buyMain .col1 .mLink img{
    margin-left: 5px;
    width: 25px;
  }
  .marketDetail .buyMain .col1 .priceDiv{
   margin-bottom: 10px;
   margin-top: 10px;
  }
  .marketDetail .buyMain .col1 .priceDiv .eth{
   width: 15px;
  }
  
  .marketDetail .buyMain .col1 .buyBtn{
   border: 2px solid #203854;
    padding: 5px 10px;
    background: #fdd803;
    width: 100%;
    border-radius: 10px;
    color: black;
    font-size: 15px;
    text-transform: uppercase;
  }
  .marketDetail .buyMain .col1 .colInner{
    
  }
  .marketDetail .buyMain .col1 .colInner p{
    padding: 8px;
    border: 1px solid #fdd803;
    width: max-content;
    border-radius: 5px;
  }
  .marketDetail .buyMain .col1 .colInner button{
    background: #fdd803;
    color: #000;
    border-radius: 10px;
    border: none;
    padding: 8px 10px;
  }
  .marketDetail .buyMain .col1 .offerBtn{
   border: 2px solid #203854;
    padding: 5px 10px;
    width: 100%;
    border-radius: 10px;
    color: white;
    font-size: 15px;
    text-transform: uppercase;
  }
  .marketDetail .attributeMain {
    padding: 18px;
    color: white;
  }
  .marketDetail .attributeMain .attRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .marketDetail .attributeMain .attRow .attrCard .title {
    font-size: 13px;
  }
  .marketDetail .attributeMain .attRow .attrCard img{
    max-height: 22px  ;
  }
  .marketDetail .attributeMain .attRow .attrCard .r3{
    font-size: 12px;
  }
  .marketDetail .attributeMain .attRow .attrCard{
    padding: 10px;
    width: 49%;
    display: inline-block;
    font-size: 13px;
    /* margin: 0 2px; */
    margin-bottom: 12px;
  
  }
  .marketDetail .attributeMain .attRow .attrCard .r2{
    font-size: 13px;
  }
  .text-bold {
    font-weight: bold;
  }
  .marketDetail .attributeMain .attRow .attrCard p{
   margin-bottom: 0;
  }
  .jc-sb {
    justify-content: space-between;
  }
  .marketDetail .collectionInfo {
    padding: 18px;
    color: white;
  }
  .marketDetail .collectionInfo h5{
    font-size: 18px;
  }
  .marketDetail .collectionInfo .name{
    font-weight: 500;
    color: white;
    margin: 10px 0;
    font-size: 14px;
  }
  .marketDetail .collectionInfo .name img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 4px;
  }
  .marketDetail .collectionInfo .desc{
    margin-bottom: 0;
    font-size: 12px;
  }
  .marketDetail .tokenInfo {
    padding: 18px;
    color: white;
    font-size: 12px;
  }
  .marketDetail .tokenInfo h5{
      font-size: 18px;
  }
  .marketDetail .tokenInfo .imgDiv img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .marketDetail .tokenInfo .address{
    font-weight: bold;
    color: #203854;
    text-decoration: none;
    display: flex; 
    align-items: center;
  }
  .marketDetail .tokenInfo .address:hover{
    color: #203854;
    text-decoration: underline;
  }
  .marketDetail .tokenInfo .imgDiv .val{
    font-weight: 500;
  }
  
  .marketMain .itemTableMain {
    color: white;
  }
  .marketMain .itemTableMain tr:nth-child(even){
    background-color: transparent;
  }
  .marketMain .itemTableMain table {
    margin: 0;
  }
  .marketMain .itemTableMain thead {
    background-color: #7e7853;
  }
  .marketMain .itemTableMain tbody tr:hover {
    background-color: #7e7853;
  }
  .marketMain .itemTableMain thead tr td{
    padding: 20px 8px;
  }
  .marketMain .itemTableMain table tr td{
    color: white;
    vertical-align: middle;
    font-size: 12px !important;
    width: max-content;
  }
  .marketMain .itemTableMain .eventDiv{
    display: flex;
  }
  .marketMain .itemTableMain .eventDiv img{
    width: 20px;
    margin-right: 7px;
  }
  .marketMain .itemTableMain .itemDiv{
    display: flex;
    align-items: center;
    width: max-content;
  }
  .marketMain .itemTableMain .itemDiv .imgdiv{
    width: 48px;
    height: 48px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 10px;
  }
  .marketMain .itemTableMain .itemDiv .imgdiv img{
    width: 100%;
  }
  .marketMain .itemTableMain .priceDiv img{
    height: 20px;
    margin-right: 5px;
    width: max-content;
  }
  .marketMain .itemTableMain .address{
    color: #fdd803;
    width: max-content;
  }
  
  .marketPlaceMain .HeaderSection {
    padding-bottom: 70px;
    margin-bottom: 40px;
    /* background: url(../assets/images/section-img1.png); */
    background-position: top;
    background-size: cover;
    height: 690px;
  }
  .td-none {
    text-decoration: none;
  }
  
  
  
  @media (min-width:576px) and (max-width:1200px) {
    .marketPlaceMain .HeaderSection {
      height: 550px;
    }
  }
  @media(max-width:576px) {
    .marketPlaceMain .marketMain .sortByMain .btn {
      width: 150px;
    }
    .marketPlaceMain .HeaderSection {
      height: 330px;
    }
    .marketPlaceMain .dataOverviewMain {
      margin-top: 100px;
    }
    .marketPlaceMain .marketMain .nftCardMain .nftCard {
        max-width: 350px;
        margin: auto;
        height:auto;
        margin-bottom: 15px;
    }
    .marketDetail .collectionInfo .desc {
      font-size: 10px;
    }
    .marketDetail .tokenInfo {
      font-size: 10px;
    }
  
    .marketDetail .nftNameMain h6 {
      font-size: 14px;
    }
    .marketDetail .nftNameMain .address span{
      font-size: 14px;
    }
    .marketDetail .buyMain .col1 .colInner h5 {
  
      font-size: 13px;
    }
    .marketDetail .buyMain .col1 .offerBtn {
      font-size: 11px;
    }
    .marketDetail .buyMain .col1 .buyBtn {
      font-size: 11px;
    }
    .marketDetail .buyMain .col1 .mLink {
      font-size: 12px;
    }
    .marketDetail .attributeMain .attRow .attrCard {
      font-size: 12px;
    }
    .marketDetail .attributeMain .attRow .attrCard .title {
      font-size: 12px;
    }
    .marketDetail .attributeMain .attRow .attrCard .r2 {
      font-size: 12px;
    }
    .marketDetail .attributeMain h5 {
      font-size: 16px;
    }
  }
  @media(max-width:768px) {
    .marketDetail .attributeMain .attRow .attrCard {
      width: 100%;
    }
  }
  
  .showmore:hover{
    background-color: #000 !important;
    color: white;
    border: 2px solid #000   !important;
  }

  
.marketPlaceMain .artworkCardMain {
    max-width: 450px;
    margin: auto;
    margin-bottom: 30px;

}



 .marketPlaceMain .artworkCard .favorite {
  position: absolute;
  z-index: 1;
  right: 13px;
  top: 13px;
}
.marketPlaceMain .artworkCard .favorite i{
  font-size: 22px;
}
.artworkCard .favorite {
  position: absolute;
  z-index: 1;
  right: 13px;
  top: 13px;
}
.artworkCard .favorite i{
  font-size: 22px;
}
.productDetail .artworkCard .content {
  padding: 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 .artworkCard .content {
  padding: 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 .artworkCard {
  padding: 0;
  position: relative;
    background: rgba(255,255,255,0.1);
    border-radius: 20px;
    color: #000;
    overflow: hidden;
    /* border: 1px solid #ACAEB1; */
    cursor: pointer;
    /* margin-bottom: 30px; */
    transition: all 0.3;
}
 .artworkCard:hover {
  box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 30%);
  transition: all 0.3;
}
.artworkCard .reserveBtn {
  padding: 7px 20px;
  border-radius: 25px;
  color: #fff;
  font-size: 12px;
  border: none;
}
 .artworkCard .favorite{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 7px;
}
.red-color {
  color: red;
}



 .artworkCard .imgDiv {
    width: 100%;
    height: 280px;
    /* border-radius: 30px; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 400px;
    overflow: hidden;
}

 .artworkCard .imgDiv img {
    /* max-width: 100%; */
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
    width: auto;
    height: auto;
    /* min-width: 100%; */
    /* height: -moz-fit-content; */
    /* height: fit-content; */
}
 .artworkCard .nameMain {
  margin-bottom: 10px;
}
 .artworkCard .nameMain h6 {
  font-size: 23px;
  font-weight: normal;
  margin-bottom: 5px;
  font-family: DbGillSiam;
}
 .artworkCard .nameMain p {
  margin-bottom: 0;
  font-size: 17px;
}

 .artworkCard .content {
    padding-top: 15px;
}

 .artworkCard .content .category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

 .artworkCard .content .category h5, h6 {
    margin: 0;
    font-weight: bold;
}

 .artworkCard .content .category p {
    margin: 0;
    font-size: 14px;
}

 .artworkCard .content .profileDiv {
    display: flex;
    align-items: center;
}

 .artworkCard .content .profileDiv .imgDiv {
    width: 44px;
    height: 44px;
    overflow: hidden;
    border-radius: 50%;
    background: #0f0a41;
    margin-right: 10px;
}
.text-underline {
  text-decoration: underline;
}
.bb-1 {
  border-bottom: 1px solid #000;
}

 .artworkCard .content .profileDiv .imgDiv img {
    max-width: 100%;
    height: auto;
}

 .artworkCard .content .profileDiv .amount {
    
}
 .artworkCard .content .profileDiv .amount .pri {
    font-size: 15px;
    margin-bottom: -8px;
}
 .artworkCard .content .profileDiv .amount .am {
    font-size: 23px;
}
.artworkCard .themeBtn {
  margin-top: 5px;
  padding: 1px 10px;
  font-size: 18px;
}
.noDAtaHead {
  text-align: center;
  color: white;
}


  @media (max-width:1400px) and (min-width:1200px) {
    .marketPlaceMain .artworkCard .imgDiv {
        height: 200px;
    }
  }
  @media (max-width:1200px) and (min-width:992px) {
    .marketPlaceMain .artworkCard .imgDiv {
      height: 320px;
    }
    .marketPlaceMain .marketMain .nftCardMain .nftCard {
      font-size: 9px;
    }
    .mkContainer {
      max-width: 990px;
    }
  }
  @media (max-width:992px) and (min-width:768px) {
    .marketPlaceMain .artworkCard .imgDiv {
      height: 222px;
    }
    .marketPlaceMain .marketMain .title {
      font-size: 10px;
    }
  }