.profilePage .banner {
    height: 320px;
    overflow: hidden;
}
.profilePage .banner .img{
    min-width: 100%;
    height: 100%;
}
.profilePage .profileImgDiv {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    padding: 7px;
    background-color: white;
    overflow: hidden;
    margin-top: -140px;
    position: relative;
    z-index: 1;
}
.profilePage .profileImgDiv img{
    /* width: 100%; */
    height: 100%;
    width: auto;
    object-fit: contain;
    object-position: center;
}
.profilePage .sidebarContent {
    color: #231F20;
    padding-bottom: 40px;
}
.profilePage .sidebarContent h3{
    font-size: 40px;
    color: #231F20;
    margin: 0;
    font-weight: bold;
}
.font-700 {
    font-weight: 700;
}
.profilePage .sidebarContent .id {
    font-size: 25px;
}
.profilePage .sidebarContent .address{
    font-size: 22px;
}
.profilePage .sidebarContent .name{
    font-size: 19px;
    margin-top: 45px;
    margin-bottom: 10px;
}
.profilePage .sidebarContent .desc{
    font-size: 21px;
    margin-bottom: 30px;
    display: block;
}
.profilePage .sidebarContent .editBtn{
    width: 100%;
    padding: 10px;
    border: 1px solid #fff;
    background: transparent;
    border-radius: 8px;
    font-size: 21px;
}
.profilePage .userNavigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #231F20;
}
.profilePage .userNavigation .links{
    display: flex;align-content: center;
}

.profilePage .userNavigation .links .link{
    font-size: 27px;
    margin-right: 40px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
}
.profilePage .userNavigation .links .link:hover::after, .profilePage .userNavigation .links .link.active::after {
    content: '';
    width: 100%;
    position: absolute;
    height: 3px;
    background-color: black;
    left: 0;
    bottom: -10px;
    transition: all 0.3s;
}
.profilePage .userNavigation .links .link.active::after {
    content: '';
    width: 100%;
    position: absolute;
    height: 3px;
    background-color: black;
    left: 0;
    bottom: -10px;
    transition: all 0.3s;
}
.profilePage .userNavigation .offer{
    font-size: 24px;
    cursor: pointer;
}
.profilePage .artworkCard {
    padding: 0;
}

.profilePage .artworkCard .transfer {
    position: absolute;
    z-index: 1;
    left: 13px;
    top: 13px;
}
.profilePage .artworkCard .transfer i{
    font-size: 22px;
}
.profilePage .artworkCard .favorite {
    position: absolute;
    z-index: 1;
    right: 13px;
    top: 13px;
}
.profilePage .artworkCard .favorite i{
    font-size: 22px;
}
.profilePage .artworkCard .content {
    padding: 15px 10px;
}

/* edti prifile */
.editProfileModal .banner {
    height: 185px;
    overflow: hidden;
    background: rgb(255 255 255 / 30%);
    position: relative;
}
.modal-content {
    background: rgb(89 89 89 / 80%) !important;
    backdrop-filter: blur(13px);
    border-radius: 20px !important;
}
.editProfileModal .banner .editIco{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    cursor: pointer;
    display: none;
}
.editProfileModal .banner:hover .editIco{
   display: block;
}
.editProfileModal .banner .img{
    min-width: 100%;
    height: 100%;
}
.editProfileModal .profileImgDiv .imgDiv {
    background-color: #C2C2C2;
    width: 100%;
    height: 100%;
    border-radius: 50%;

}
.editProfileModal .profileImgDiv {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    padding: 7px;
    background-color: white;
    overflow: hidden;
    margin-top: -104px;
    position: relative;
    z-index: 1;
}
.editProfileModal .profileImgDiv img{
    /* width: 100%; */
    height: 100%;
    width: auto;
    object-fit: contain;
    object-position: center;
}
.editProfileModal .profileImgDiv .editIco{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    cursor: pointer;
    display: none;
}
.editProfileModal .profileImgDiv:hover .editIco{
    display: block;
 }
.editProfileModal .profileImgDiv .editIco input{
    display: none;
}
.editProfileModal .banner .editIco input{
    display: none;
}
.editProfileModal .imgText{
    font-size: 16px;
    margin-left: 190px;
    margin-top: -40px;
}
.editProfileModal .imgText p{
    margin: 0;
}
.modal-dialog {
    max-width: 700px;
}
.editProfileModal .eForm {
    padding: 0 20px;
}
.editProfileModal .eForm label{
    font-size: 20px;
    margin-bottom: 3px;
}
.editProfileModal .eForm .form-group{
    margin-bottom: 20px;
}
.editProfileModal .eForm .form-group input{
    background: transparent;
    font-size: 19px;
}
.editProfileModal .eForm .form-group textarea{
    background: transparent;
}
.editProfileModal .eForm .btnMain{
    display: flex;
    justify-content: space-between;
}
.editProfileModal .eForm .btnMain .Cancel{
    padding: 8px 30px;
    border: 1px solid #fff;
    border-radius: 8px;
    background: transparent;
    color: #fff;
    font-size: 19px;
}
.editProfileModal .eForm .btnMain .Cancel:hover{
    padding: 8px 30px;
    border: 1px solid #fff;
    border-radius: 8px;
    background: #000;
    color: #fff;
}

.editProfileModal .eForm .btnMain .save{
    padding: 8px 30px;
    border: 1px solid transparent;
    border-radius: 12px;
    color: #fff;
    font-size: 19px;
}
.editProfileModal .eForm .btnMain .Cancel:hover{
    border: 1px solid #000;
    background: transparent;
    color: black;
}
.editProfileModal h2 {
    padding: 10px 20px;
    padding-top: 15px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
}
.modal-body {
    padding: 0 !important;
}

/* edti prifile */
@media (max-width:576px) {
    .profilePage .userNavigation .links .link {
        font-size: 18px;
        margin-right: 20px;
    }
    .profilePage .userNavigation .offer {
        font-size: 16px;
    }
    .editProfileModal .imgText {
        margin: 0;
        text-align: center;
    }
}
.create-loader{
    border: 5px solid #f3f3f3;
    animation: spin 1s linear infinite;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: inline-block;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  