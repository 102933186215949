.welcomePage {
    overflow: hidden;
    position: relative;
}
.welcomePage .effect1{
    position: absolute;
    z-index: -1;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 1300px;
    opacity: 0.7;
    width: 1800px;
}
.welcomePage .section1 {
    height: 650px;
    background: url(../../images/welcomeBanner.jpg);
    background-size: cover;
    background-position: center;
    background-color: #09030A;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.welcomePage .section1 .content{
    padding: 70px 0;
    text-align: center;
}
.welcomePage .section1 .content .tokenImg{
    max-width: 200px;
    margin: auto;
}
.welcomePage .section1 .content .welcome{
    color: #fff;
    font-size: 55px;
}
.welcomePage .section1 .content .first{
    color: #fff;
    font-size: 55px;
}
.welcomePage .section1 .content .text{
    color: #fff;
    font-size: 40px;
}
.welcomePage .newItemSec {
    padding: 40px 0;
}
.welcomePage .newItemSec h1{
    color: white;
    font-size: 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ACAEB1;
    margin-bottom: 40px;
}
.welcomePage .contractRow {
    padding: 30px 0;
    margin: 60px 0;
}
.welcomePage .contractRow .content{
    padding: 30px;
    background: rgba(255,255, 255,0.1);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.welcomePage .contractRow .content p{
   margin-bottom: 0;
   font-size: 20px;
   word-break: break-all;   
}
.welcomePage .contractRow .content .buyBtn{
   border-radius: 8px;
   padding: 10px 40px;
   color: white;
   font-size: 30px;
}
.welcomePage .contractRow .content .buyBtn span{
   font-size: 19px;
}
@media (max-width: 992px) {
    .welcomePage .contractRow .content {
        flex-direction: column;
    }
    .welcomePage .contractRow .content .buyBtn {
        margin-top: 20px;
    }
    footer .miniFooter .colTwo {
        margin-top: 20px;
    }
}