.WalletModal {
  color: #17447E;
}

.WalletModal h1 {
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
}

.WalletModal .walletRow {
  display: flex;
  justify-content: center;
}

.WalletModal .metamask {
  margin-right: 25px;
}

.WalletModal .walletImg {
  width: 210px;
  margin: auto;
  margin-bottom: 30px;
}

.WalletModal .walletConnect .walletImg {
  width: 226px;
  margin: auto;
  margin-bottom: 30px;
}

.WalletModal .btnDiv {
  text-align: center;
  margin-bottom: 30px;
}

.WalletModal .btnDiv button {
  /* background: #05B4EF; */
  background-image: linear-gradient(45deg, #D279F5 0%, #422E9B 50%);
  /* background-image: linear-gradient(90deg,#1fa2ff 0,#12d8fa 51%,#1fa2ff); */
  background-size: 200%;
  width: 145px;
  height: 44px;
  border-radius: 12px;
  border: none;
  font-weight: bold;
  color: #fff;
  margin: auto;
  transition: all 0.3s;
}

.WalletModal .btnDiv button:hover {
  background-position: 99%;
  transition: all 0.3s;
}

.WalletModal .helpDv {
  font-size: 14px;
  text-align: center;
  padding-bottom: 30px;
}

.WalletModal .helpDv img {
  width: 20px;
  margin-right: 5px;
}

.WalletModal .closeIcon {
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
}

.WalletModal .closeIcon i {
  font-size: 25px;
  color: #707070;
}


@media(max-width:576px) {

  .WalletModal .helpDv {
      font-size: 12px;
  }

  .WalletModal .btnDiv button {
      font-size: 14px;
      width: 140px;
      height: 41px;
  }

  .WalletModal .walletImg {
      width: 120px;
  }

  .WalletModal .walletConnect .walletImg {
      width: 129px;
  }

  .WalletModal .metamask {
      margin-right: 5px;
  }

  .WalletModal h1 {
      font-size: 25px;
  }
}


@media(max-width:340px) {
  .WalletModal .btnDiv button {
      font-size: 12px;
      width: 110px;
      height: 32px;
  }

  .WalletModal .walletImg {
      width: 110px;
  }

  .WalletModal .walletConnect .walletImg {
      width: 119px;
  }
}
.basic-single{
  width: 100%;
  text-align: left;
}
.country-option {
  color: black;
  /* padding: 2px 18px; */
}
.country-option > span > span {
  color: black;
  /* padding: 2px 18px; */
}
.country-option > span {
  color: black;
  /* padding: 2px 18px; */
}
.country-option:hover{
  backdrop-filter: blur(30px);
}
