/* @font-face { font-family: "Somerton Dense"; src: url('./somerton_dense/Somerton Dense df.otf'); }  */
/* @font-face { font-family: "Poppins"; src: url('./Fonts/Poppins/Poppins-Regular.ttf'); }  */
/* @font-face { font-family: "PoppinsBold"; src: url('./Fonts/Poppins/Poppins-Bold.ttf'); }  */
/* @font-face { font-family: "Montserrat"; src: url('./Fonts/Montserrat/Montserrat-SemiBold.ttf'); }  */

@font-face {
    font-family: DbGillSiam;
    src: url('./Fonts/DB\ Gill\ Siam\ X\ Li\ v3.2.ttf');
}
@font-face {
    font-family: MadeBonVoyage;
    src: url('./Fonts/made_bon_voyage_regular.otf');
}


::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;

}

:root {
    --theme-color: #17447E;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #001226 !important;
    padding: 0;
    margin: 0;
    color: white;
}

#root {
    overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
    /* font-family: MariWeather !important; */
    color: #fff;
    font-family: MadeBonVoyage;
}

.montserat {
    /* font-family: Montserrat !important; */
}

button {
    /* font-family: Montserrat !important; */
}

button, p, div, a, span ,input,select{
    font-family: DbGillSiam;
    color: #fff;
}

.montseratMedium {
    /* font-family: MontserratMedieum !important; */
}

p {
    /* font-family: MontserratMedieum !important; */
}

.back-color {
    color: #080027 !important;
}

.ai-center {
    align-items: center;
}

.jc-center {
    justify-content: center;
}

.custom-nav .navbar-brand {
    width: 125px;
}

.custom-nav .nav-link {
    color: #fff !important;
    font-size: 20px;
    font-weight: 500;
    transition: all .3s;
    background: linear-gradient(270deg, #fff 0, #fff) 0 100% transparent no-repeat;
    background-size: 0% 2px;
    width: max-content;
}
.custom-nav .resourceLink {
    position: relative;
    z-index: 2;
}
.custom-nav .resourceLink:hover .dropDown {
    display: block;
}
.custom-nav .resourceLink:focus .dropDown {
    display: block;
}
.custom-nav .resourceLink .dropDown{
    display: none;
    position: absolute;
    padding: 15px 10px;
    background-color: white;
    border-radius: 10px;
    transform: translate(-50%, 0);
    left: 50%;
    top: 39px;
}
.custom-nav .resourceLink ul{
    list-style: none;
    padding: 0;
}
.custom-nav .resourceLink ul li {
    padding: 5px 0;
    border-bottom: 1px solid rgb(227, 227, 227);
}
.custom-nav .resourceLink ul li:last-child {
    border-bottom: none;
}
.custom-nav .resourceLink ul li a{
    color: #000;
    text-decoration: none;
}
.custom-nav .resourceLink ul li a:hover{
    opacity: 0.7;
    text-decoration: none;
}
.custom-nav .socialIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 10px; */
}
.custom-nav .socialIcons a{
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-nav .socialIcons .discord{
    max-width: 100%;
    width: 20px;
    filter: brightness(1) invert(1);
}

.custom-nav .nav-link:hover {
    transition: all .3s;
    background: linear-gradient(270deg, #fff 0, #fff) 0 100% transparent no-repeat;
    background-size: 100% 2px;
}
/* .custom-nav .walletBtn span {
    color: #000;
}
.custom-nav .walletBtn span:hover {
    color: #fff;
} */
.custom-nav .walletBtn {
    background: #fff;
    font-size: 19px;
    padding: 13px 40px;
    border-radius: 8px;
}
.custom-nav .walletIco {
    margin-left: 20px;
    position: relative;
}
.custom-nav .walletIco img{
    width: 30px;
    cursor: pointer;
}
.custom-nav .walletIco .amount{
    display: none;
    background: #fff;
    color: #000;
    padding: 5px 15px;
    position: absolute;
    width: max-content;
    border-radius: 10px;
    min-width: 100px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    top: 45px;
    transform: translate(-50%, 0px);
    left: 50%;
    z-index: 1;
}
.custom-nav .walletIco:hover .amount {
    display: block;
}

.custom-nav .walletBtn:hover {
    background: #4A80C7;
    color: #fff;
}
.custom-nav .col2 {
    display: flex;
    align-items: center;
}
.custom-nav .col2 .searchNav{
    width: 290px;
    background: white;
    height: 38px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 7px 0px;
    margin-right: 20px;
}
.custom-nav .col2 .searchNav img{
    margin-right: 8px;
}
.custom-nav .col2 .searchNav input{
    width: 100%;
    height: 100%;
    color: black;
    border: none;
    outline: none;
    font-size: 19px;
}
.custom-nav .col2 .searchNav input::placeholder{
    color: black;
}
footer {
    /* background: #606060; */
    background: linear-gradient(45deg, #D279F5, #422E9B);
    padding: 30px 0;
    padding-top: 0;
}
footer .col1{
    width: max-content;
    margin: auto;
}
footer .copyRight {
    padding: 10px 0;
    font-size: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #C5C6C8;
    margin-top: 30px;
}
footer .copyRight a{
    color: #fff;
    text-decoration: none;
}
footer .desc {
    color: #fff;
    /* text-align: justify; */
    font-size: 22px;
    max-width: 248px;
}
footer .logoMain {
    width: 160px;
    margin-bottom: 20px;
}
footer .logoMain img{
    max-width: 100%;
}
footer h6 {
    color: #fff;
    font-size: 17px;
    margin: 25px 0;
    margin-top: 35px;
}
footer ul{
    padding-left: 0;
    list-style: none;
}
footer ul li {
    margin: 5px 0;
}
footer ul li a{
    font-size: 19px;
    text-decoration: none;
    color: #fff;
}
footer ul li a:hover{
    text-decoration: none;
    color: #fff;
    opacity: 0.8;
}
footer .miniFooter {
    /* background: #C5C6C8; */
    background: #001226 ;
    padding: 35px 0;
}
footer .miniFooter .colOne{
    background: #001226 ;
}
footer .miniFooter .colOne p{
    font-size: 19px;
}
footer .miniFooter .colTwo p{
    font-size: 19px;
}
.ai-fe {
    align-items: flex-end;
}
footer .colTwo .socialMain img{
    margin-right: 5px;
    max-width: 50px;
    transition:all 0.3s;
}
footer .colTwo .socialMain img:hover {
    transform: scale(1.1);
    transition:all 0.3s;
}
footer .miniFooter .mail {
    display: flex;
    align-items: center;
}
footer .miniFooter .mail input{
    width: 100%;
    padding: 6px 20px;
    border: none;
    border-radius: 8px;
    color:#96989B;
    font-size: 19px;
}
footer .miniFooter .mail input::placeholder {
   
    color:#96989B;
}
footer .miniFooter .mail button{
    padding: 5px 20px;
    margin-left: 10px;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 19px;
}

.color-white {
    color: #fff;
}

.text-left {
    text-align: left;
}

.logoutIcon img {
    width: 25px !important;
    margin-left: 20px;
}

.logoutIcon span {
    font-size: 14px;
    font-weight: 600;
    margin-left: 8px;
    color: white;
}

.logoutIcon:hover {
    cursor: pointer;
}



.w-mc {
    width: max-content;
}

.pr {
    position: relative;
}

.d-flex {
    display: flex;
}

.jc-sb {
    justify-content: space-between;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}




.imgLoader {
    width: 45px;
    height: 45px;
    display: inline-block;
    padding: 0px;
    border-radius: 100%;
    border: 5px solid;
    border-top-color: rgb(255, 255, 255);
    border-bottom-color: rgba(255, 255, 255, 0.3);
    border-left-color: rgb(255, 255, 255);
    border-right-color: rgba(255, 255, 255, 0.3);
    -webkit-animation: imgLoader 1s ease-in-out infinite;
    animation: imgLoader 1s ease-in-out infinite;
}
.mintModalBtn {
    display: flex;
}
.collectiblesCard .imgDiv{
    min-height: 250px;
    max-height: 250px;
    width: 100%;
    overflow: hidden;
}
@keyframes imgLoader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes imgLoader {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

.globalLoader {
    position: fixed;
    top: 0;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    width: 100vw;
    left: 0;
    height: 100vh;
    background: rgba(0, 0, 0, .2);
    z-index: 1111;
}

.globalLoader .loader {
    width: 100%;
    max-width: 300px;
    padding: 40px 0;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: #fff;
    border-radius: 20px;
    position: fixed;
}

.globalLoader .msg {
    color: black;
    font-size: 22px;
    font-weight: 600;
}

.navbar {
    background: linear-gradient(45deg, #4A80C7, #CB71E8);
}
.h-100 {
    height: 100%;
}


@media(min-width:1200px) and (max-width:1400px) {
    footer .desc {
        max-width: 311px;
    }
  
}

@media(min-width:992px) and (max-width:1200px) {

}



@media(min-width:576px) and (max-width:768px) {
 
}

@media(min-width:992px) {
    .custom-nav .nav-item {
        margin: 0 10px ;
    }
}

@media(max-width:992px) {
    footer .colTwo .socialMain img {
        max-width: 40px;
    }
    .custom-nav .walletIco {
        margin-top: 10px;
    }
   

    .logoutIcon {
        margin-top: 20px;
    }

}

@media(max-width:768px) {
    footer .copyRight {
        flex-direction: column;
    }
    footer .copyRight .copy{
        margin-bottom: 10px;
    }
}

@media(max-width:576px) {
    .profilePage .profileImgDiv {
        width: 150px !important;
        height: 150px !important;
        margin-top: -90px !important;
    }
    .custom-nav .col2 {
        flex-direction: column;
    }
    .custom-nav .col2 .searchNav {
        width: 100%;
        margin: 0;
    }
    .custom-nav .walletBtn {
        width: 100%;
        margin-top: 10px;
    }
    footer h6 {
        font-size: 16px;
    }
    footer ul li a {
        font-size: 14px;
    }
    footer ul li {
        margin: 10px 0;
    }
    footer .copyRight {
        font-size: 17px;
    }
}

@media(min-width:1200px) and (max-width:1400px) {}


@media(min-width:992px) and (max-width:1200px) {
    .custom-nav .walletBtn {
        padding: 14px 15px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    
}
.time-remaining{
    font-size: 21px;
    line-height: 29px;
    color: #12243a;
    font-weight: bold;
    font-family: "Gotham-Font-Navbar";
    text-align: center;
  }
  .list-status-btn{
    cursor: pointer;
    margin-top: 10px;
    padding: 10px 15px;
    border: 1px solid grey;
    border-radius: 8px;
  }
  
  .listing-modal{
    font-family: 'Mulish', sans-serif;
  }
  