.create-header{
  font-size: 42px;
  font-weight: bolder;
}

/* #img-upload{
  cursor: pointer;
  border: thin dashed;
  color: grey;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 25px;
  border-radius: 12px;
  -webkit-filter: drop-shadow(0 0 2.5px rgba(19,19,19,.16));
  filter: drop-shadow(0 0 2.5px rgba(19,19,19,.16));
  background-image: radial-gradient(circle at center,#1f4169 0,#0f2135 100%);
} */

.card-img{
  border-radius: 25px;
}
.card{
  border-radius: 25px;
  border: none;
}
#title::placeholder, .property-value::placeholder, .property-key::placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white
}
#title{
  background: none;
  border: none;
  caret-color: #fff;
  height: 40px;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 33px;
  color: #fff;
  font-family: "Mulish";
  width: 100%;
  box-shadow: inset 0 0 10px #58006a;
  -moz-box-shadow: inset 0 0 10px #58006a;
  -webkit-box-shadow: inset 0 0 10px #58006a;
  border-radius: 10px;
  background-color: #041426;
}
#custom-select-collection-categ,#select-collection{
  box-shadow:inset 0 0 10px #58006a;
  -moz-box-shadow:inset 0 0 10px #58006a;
  -webkit-box-shadow:inset 0 0 10px #58006a;
  height: 40px;
  border-radius: 10px;
  background-color: #041426;
  color: white;
  font-style: italic;  
  
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../../images/download.png'),linear-gradient(#051427 100%,#051427 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .9em top 50%, 0 0;
  background-size: .65em auto, 100%;
  padding-left: 10px !important;
  border: none !important;
  cursor: pointer;
}

#custom-select-collection-categ:focus-visible,#select-collection:focus-visible{
  outline: 0px !important;
}

#descript::placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  color: white;
  font-weight: lighter;
  font-style: italic;
}

.create-loader{
  border: 5px solid #f3f3f3;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: inline-block;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* #descript{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  height: 40px;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 33px;
  font-family: "Mulish";
  height: 150px;
  width: inherit;
  box-shadow:inset 0 0 10px grey;
  -moz-box-shadow:inset 0 0 10px grey;
  -webkit-box-shadow:inset 0 0 10px grey;
  border-radius: 10px;
  background-color: #041426;
} */

#title:focus, #descript:focus, .property-value:focus, .property-key:focus{
  outline: none;
}
.create-btn{
  filter: drop-shadow(1.338px 1.486px 0.5px rgba(19,19,19,.17));
  background: linear-gradient(45deg, #D279F5, #422E9B);
  border: none;
  color: #fff;
  display: inline-block;
  padding: 5px 30px;
  border-radius: 25px;
  font-size: 24px;
  max-width: 203px;
  width: 100%;
  /* -webkit-filter: drop-shadow(1.338px 1.486px .5px rgba(19,19,19,.17)); */
}
#royaltyfee{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  height: 40px;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 33px;
  color: #ffffff;
  font-family: "Mulish";
  width: 100%;
  box-shadow:inset 0 0 10px #58006a;
  -moz-box-shadow:inset 0 0 10px #58006a;
  -webkit-box-shadow:inset 0 0 10px #58006a;
  border-radius: 10px;
  background-color: #041426;
  padding-bottom: 6px;
}

#royaltyfee:focus, .social-link-input:focus{
  outline: none;
}

#royaltyfee::placeholder, .social-link-input::placeholder{
  font-size: 14px !important;
  opacity: 0.8;
  font-weight: lighter;
  font-style: italic;
  color:white;
}
.social-img{
  position: absolute;
  height: 98%;
  padding-right: 14px;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  top: 0px;
  /* left: 3px; */
  box-shadow:inset 0 0 10px #58006a;
  -moz-box-shadow:inset 0 0 10px #58006a;
  -webkit-box-shadow:inset 0 0 10px #58006a;
  border-radius: 10px;
}
.social-link-input{
  background: none;
  color: white;
  border: none;
  caret-color: white;
  height: 40px;
  padding-left: 60px;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 33px;
  color: #ffffff;
  font-family: "Mulish";
  width: 100%;
  box-shadow:inset 0 0 10px #58006a;
  -moz-box-shadow:inset 0 0 10px #58006a;
  -webkit-box-shadow:inset 0 0 10px #58006a;
  border-radius: 10px;
  background-color: #041426;
  padding-bottom: 4px;
}